<div class="page">
    <div class="header">
        <div class="title-page">
            <span class="title-text">{{ title }}</span>
        </div>
    </div>
    <div class="content">
        <div class="boxVideo">
            <img 
                [hidden]="submitted"
                mat-card-image
                class="video"
                src="/assets/red-cat.png"
            />
            <video 
                [hidden]="!submitted"
                id="load" 
                class="video" 
                playsinline preload
            >
                <source src="/assets/wheel_of_fortune.mp4" type="video/mp4">
            </video>
        </div>
    </div>
    <div class="footer">
        <button (click)="onDrawLotterey()" [disabled]="submitted" [class]="submitted? 'gotch-button-disabled' : 'gotch-button'" >
            {{ nameButtonAction }} <mat-icon *ngIf="submitted"><mat-spinner color="accent" diameter="20"></mat-spinner></mat-icon>
        </button>
    </div>
</div>
