<div class="page">
    <div class="header">
        <div class="button-back">
            <button mat-button (click)="backClicked()">
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
        </div>
        <div class="title-page">
            <span class="title-text"></span>
        </div>
    </div>
</div>

<div class="container-page">
    <div *ngIf="qrcodeView" class="qr-code">
        <div *ngIf="isAndroid">
            <zxing-scanner *ngIf="scannerEnabled" #scanner (scanSuccess)="scanSuccessHandler($event)">
            </zxing-scanner>
        </div>
        <div *ngIf="!isAndroid">
            <ngx-scanner-qrcode #action="scanner" [config]="config" [isBeep]="false" (event)="onEvent($event)"></ngx-scanner-qrcode>
        </div>
    </div>
    <div class="qr-code-footer">
        <p class="qr-code-footer-text">
            {{scanQRCodeTitle}}
        </p>
    </div>
</div>