<div *ngIf="isReady" class="page">
    <div class="header">
        <div class="button-back">
            <button mat-button (click)="onClose()">
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
        </div>
        <div class="title-page">
            <span class="title-text">{{ title }}</span>
        </div>
    </div>
    <div class="content">
        <div class="card">
            <mat-card [class]="isAvailable ? 'stamp-rally' : 'expired content-disabled'" [style.background]="geEvent?.color">
                    <img
                    mat-card-image
                    class="img-mat-card-image"
                    src="{{ geEvent?.image_url }}"
                />
                <mat-card-content>
                    <div class="title">{{ geEvent?.name }}</div>
                    <div class="subtitle">
                        {{ geEvent?.start_date }} - {{ geEvent?.end_date }}
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="description">
            <p [innerHtml]="geEvent?.description"></p>
        </div>
    </div>
    <div class="footer">
        <button *ngIf="isScanQRCode" (click)="goToQRCode()" class="gotch-button">
            {{ nameButtonAction }}
        </button>
        <button *ngIf="!isScanQRCode" (click)="goToCourse()" class="gotch-button">
            {{ nameButtonAction }}
        </button>
    </div>
</div>
