<div class="page">
    <div class="header">
        <div (click)="backClick()" class="button-back">
            <button mat-button>
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
        </div>
        <div class="title-page">
            <span class="title-text">{{ title }}</span>
        </div>
    </div>
    <div class="content">
        <ul>
            <li *ngFor="let spot of spots">
                <span
                    *ngIf="checkSpotFinish(spot.spot_id)"
                    class="spot-square spot-check"
                >
                    <mat-icon class="icon-spot">check</mat-icon>
                </span>
                <span
                    *ngIf="!checkSpotFinish(spot.spot_id)"
                    class="spot-square"
                >
                    <mat-icon class="icon-spot"></mat-icon>
                </span>
                <div class="spot-card" (click)="questionClick(spot.spot_id, spot.name, spot.spot_qr_code_id)">
                    <mat-card>
                        <img
                            mat-card-image
                            class="img-mat-card-image"
                            src="{{ spot.imageUrl }}"
                        />
                        <div class="spot-name">{{ spot.name }}</div>
                        <div class="btnMap">
                            <button
                                mat-mini-fab
                                class="btnMap"
                                (click)="selectSpotMapClick(spot.spot_id, spot.name)"
                            >
                                <mat-icon>location_on</mat-icon>
                            </button>
                        </div>
                    </mat-card>
                    <div class="spot-address">{{ spot.address }}</div>
                </div>
            </li>
        </ul>
    </div>
    <div class="footer">
        <button
            [class]="(!isClaimed && isCompleted && isAvailable)  ? 'gotch-button' : 'gotch-button-disabled'"
            (click)="onDrawSkill()"
        >
            {{ nameButtonAction }}
        </button>
    </div>
</div>
