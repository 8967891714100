import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import {GeEventResponse } from '../../models/ge-event/ge-event';

const eventApiUrl = environment.centerServer.domain + '/gotch-event/v1/event/{eventId}/{language}';
const languageToken = '{language}';
const eventIdToken = '{eventId}';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    constructor(private readonly http: HttpClient) {}

    getEvent(eventId: string): Observable<GeEventResponse> {
        const getURL = eventApiUrl
            .replace(languageToken, Functions.getDefaultLanguage())
            .replace(eventIdToken, eventId);
        return this.http.get<GeEventResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
