import { Component, OnInit } from '@angular/core';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { ActivatedRoute } from '@angular/router';
import { Functions } from 'src/app/core/helpers/functions';
import { StampRallyCustomerPrizeRequest } from 'src/app/core/models/stamp-rally-customer-prize/stamp-rally-customer-prize';
import { StampRallyCustomerPrizePaperVoucherService } from 'src/app/core/services/stamp-rally-customer-prize-paper-voucher/stamp-rally-customer-prize-paper-voucher.service';
declare function sendData(data: string): string;

@Component({
    selector: 'app-thank-you-paper-voucher',
    templateUrl: './thank-you-paper-voucher.component.html',
    styleUrls: ['./thank-you-paper-voucher.component.css'],
})
export class ThankYouPaperVoucherComponent implements OnInit {
    courseId = '';
    eventId = '';
    title? = 'Thank you!';
    nameButtonAction? = 'Finish'
    isCompleted = false;
    translation?: Translation

    constructor(
        private stampRallyCustomerPrizePaperVoucherService: StampRallyCustomerPrizePaperVoucherService,
        private activeRoute: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.loadTranslation();
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.onPrize();
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null){
            this.translation = JSON.parse(translation);
            if (this.translation != null) {
                this.title = this.translation.thankYouVoucherTitle;
                this.nameButtonAction = this.translation.thankYouVoucherButton;
            }
        }
    }

    onPrize() {
        const stampRallyCustomerPrizeRequest: StampRallyCustomerPrizeRequest = {
            course_id: this.courseId,
            event_id: this.eventId,
            language: Functions.getDefaultLanguage(),
        };
        this.stampRallyCustomerPrizePaperVoucherService
            .postPaperVoucherPrize(stampRallyCustomerPrizeRequest)
            .subscribe((result) => {
                if (result.statusCode === 1000) {
                    this.isCompleted = true;
                }
            }
        );
    }

    onClose() {
        if (this.isCompleted) {
            const close = {
                close: true
            }
            sendData(JSON.stringify(close));    
        }
    }
}
