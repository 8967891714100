import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialCommonModule } from './shared/material-common/material-common.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalHttpInterceptorService } from './core/services/interceptor/global-http-interceptor.service';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { MainComponent } from './components/main/main.component';
import { CloseComponent } from './components/close/close.component';
import { DialogModalComponent } from './shared/dialog-modal/dialog-modal..component';
import { GoogleMapsModule } from '@angular/google-maps';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
// Stamp Rally - Coupon
import { EventStampRallyComponent } from './components/stamp-rally/event-detail/event-stamp-rally.component';
import { CourseComponent } from './components/stamp-rally/course/course.component';
import { SpotComponent } from './components/stamp-rally/spot/spot.component';
import { MapsComponent } from './components/stamp-rally/maps/maps.component';
import { QuestionComponent } from './components/stamp-rally/question/question.component';
import { PrizeComponent } from './components/stamp-rally/prize/prize.component';
// Lottery - Coupon
import { EventLotteryComponent } from './components/lottery/event-detail/event-lottery.component';
import { DrawLotteryComponent } from './components/lottery/draw-lottery/draw-lottery.component';
import { DrawLotteryResultComponent } from './components/lottery/draw-lottery-result/draw-lottery-result.component';
// Coupon
import { EventCouponComponent } from './components/coupon/event-detail/event-coupon.component';
import { CouponPrizeComponent } from './components/coupon/coupon-prize/coupon-prize.component';
// Free Voucher
import { EventFreeVoucherComponent } from './components/free-voucher/event-detail/event-free-voucher.component';
// Stamp Rally - Paper Voucher
import { EventDetailPaperVoucherComponent } from './components/stamp-rally-paper-voucher/event-detail/event-detail-paper-voucher.component';
import { CoursePaperVoucherComponent } from './components/stamp-rally-paper-voucher/course/course-paper-voucher.component';
import { SpotPaperVoucherComponent } from './components/stamp-rally-paper-voucher/spot/spot-paper-voucher.component';
import { QrCodePaperVoucherComponent } from './components/stamp-rally-paper-voucher/qr-code/qr-code-paper-voucher.component';
import { MapsPaperVoucherComponent } from './components/stamp-rally-paper-voucher/maps/maps-paper-voucher.component';
import { QuestionPaperVoucherComponent } from './components/stamp-rally-paper-voucher/question/question-paper-voucher.component';
import { ThankYouPaperVoucherComponent } from './components/stamp-rally-paper-voucher/thank-you/thank-you-paper-voucher.component';
// Stamp Rally - Shop Voucher
import { EventDetailShopVoucherComponent } from './components/stamp-rally-shop-voucher/event-detail/event-detail-shop-voucher.component';
import { CourseShopVoucherComponent } from './components/stamp-rally-shop-voucher/course/course-shop-voucher.component';
import { SpotShopVoucherComponent } from './components/stamp-rally-shop-voucher/spot/spot-shop-voucher.component';
import { QrCodeShopVoucherComponent } from './components/stamp-rally-shop-voucher/qr-code/qr-code-shop-voucher.component';
import { MapsShopVoucherComponent } from './components/stamp-rally-shop-voucher/maps/maps-shop-voucher.component';
import { QuestionShopVoucherComponent } from './components/stamp-rally-shop-voucher/question/question-shop-voucher.component';
import { PrizeShopVoucherComponent } from './components/stamp-rally-shop-voucher/prize/prize-shop-voucher.component';
// Stamp Rally - Net Game - Coupon
import { EventDetailNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/event-detail/event-detail-net-game-coupon.component';
import { CourseNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/course/course-net-game-coupon.component';
import { SpotNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/spot/spot-net-game-coupon.component';
import { QrCodeNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/qr-code/qr-code-net-game-coupon.component';
import { MapsNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/maps/maps-net-game-coupon.component';
import { QuestionNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/question/question-net-game-coupon.component';
import { DrawSkillNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/draw-skill/draw-skill-net-game-coupon.component';
import { DrawLotteryNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/draw-lottery/draw-lottery-net-game-coupon.component';
import { DrawLotteryNetGameResultCouponComponent } from './components/stamp-rally-net-game-coupon/draw-lottery-result/draw-lottery-net-game-result-coupon.component';

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        QrCodeComponent,
        EventStampRallyComponent,
        EventLotteryComponent,
        EventCouponComponent,
        CouponPrizeComponent,
        CourseComponent,
        SpotComponent,
        QuestionComponent,
        PrizeComponent,
        MainComponent,
        MapsComponent,
        DrawLotteryComponent,
        DrawLotteryResultComponent,
        CloseComponent,
        DialogModalComponent,
        EventFreeVoucherComponent,
        EventDetailPaperVoucherComponent,
        CoursePaperVoucherComponent,
        SpotPaperVoucherComponent,
        QrCodePaperVoucherComponent,
        MapsPaperVoucherComponent,
        QuestionPaperVoucherComponent,
        ThankYouPaperVoucherComponent,
        EventDetailShopVoucherComponent,
        CourseShopVoucherComponent,
        SpotShopVoucherComponent,
        MapsShopVoucherComponent,
        QuestionShopVoucherComponent,
        PrizeShopVoucherComponent,
        QrCodeShopVoucherComponent,
        EventDetailNetGameCouponComponent,
        CourseNetGameCouponComponent,
        SpotNetGameCouponComponent,
        QrCodeNetGameCouponComponent,
        MapsNetGameCouponComponent,
        QuestionNetGameCouponComponent,
        DrawSkillNetGameCouponComponent,
        DrawLotteryNetGameCouponComponent,
        DrawLotteryNetGameResultCouponComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialCommonModule,
        HttpClientModule,
        GoogleMapsModule,
        ZXingScannerModule,
        NgxScannerQrcodeModule,
        [LottieModule.forRoot({ player: playerFactory })],
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalHttpInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
