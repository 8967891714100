<div class="page">
    <div class="header">
        <div class="button-back" (click)="onBackClick()">
            <button mat-button>
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
        </div>
        <div class="title-page">
            <span class="title-text">{{ title }}</span>
        </div>
    </div>
    <div *ngIf="isReady" class="content">
        <div class="questions">
            <h2>{{ question?.question_title }}</h2>
            <p [innerHTML]="question?.question"></p>
        </div>
        <div *ngIf="question?.type === 'RADIO'" class="answers">
            <mat-radio-group
                aria-labelledby="question-radio-group-label"
                class="question-radio-group"
            >
                <mat-radio-button
                    class="question-radio-button"
                    *ngFor="let answer of question?.answers"
                    [value]="answer.answer_id"
                    (change)="onCheckRadio(answer)"
                    [checked]="answerSelect === answer.answer_id"
                >
                <span [class]="answerTitle && answerSelect === answer.answer_id && answer.is_correct? 'correct' :answerTitle && answerSelect === answer.answer_id &&  !answer.is_correct? 'wrong' : ''">
                    {{answerTitle && answerSelect === answer.answer_id && answer.is_correct? answerTitle + ' - ' + correct : answerTitle && answerSelect === answer.answer_id && !answer.is_correct? answerTitle + ' - ' + wrong : answer.answer}}
                </span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="question?.type === 'TEXT'" class="answers">
            <mat-form-field appearance="fill" class="question-text">
                <mat-label></mat-label>
                <textarea
                    matInput
                    [formControl]="textQuestionFC"
                    (keyup)="onCommentChange()"
                    [(ngModel)]="answerResponse"
                ></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="footer">
        <button
            [class]="
                isDisabledButton ? 'gotch-button-disabled' : 'gotch-button'
            "
            [disabled]="isDisabledButton"
            (click)="onSend()"
            class="gotch-button"
        >
            {{ nameButtonAction }}
        </button>
    </div>
</div>
