import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { QuestionResponse } from '../../models/question/question';

const questionsApiUrl = environment.centerServer.domain + '/gotch-event/v1/spot/{spotId}/questions/{language}';
const languageToken = '{language}';
const spotIdToken = '{spotId}';

@Injectable({
    providedIn: 'root',
})
export class QuestionService {
    constructor(private readonly http: HttpClient) {}

    getQuestions(spotId: string): Observable<QuestionResponse> {
        const getURL = questionsApiUrl
            .replace(languageToken, Functions.getDefaultLanguage())
            .replace(spotIdToken, spotId);
        return this.http.get<QuestionResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}