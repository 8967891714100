import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { Spot } from 'src/app/core/models/spot/spot';
import { StampRallyCustomerAnswersResponse } from 'src/app/core/models/stamp-rally-customer-answer/stamp-rally-customer-answer';
import {
    StampRallyCustomerPrizeRequest,
    StampRallyCustomerPrizeResponse
} from 'src/app/core/models/stamp-rally-customer-prize/stamp-rally-customer-prize';
import { SpotService } from 'src/app/core/services/ge-spot/ge-spot.service';
import { StampRallyCustomerAnswerService } from 'src/app/core/services/stamp-rally-customer-answer/stamp-rally-customer-answers.service';
import { StampRallyCustomerPrizeService } from 'src/app/core/services/stamp-rally-customer-prize/stamp-rally-customer-prize.service';

@Component({
    selector: 'app-spot-shop-voucher',
    templateUrl: './spot-shop-voucher.component.html',
    styleUrls: ['./spot-shop-voucher.component.css'],
})
export class SpotShopVoucherComponent implements OnInit {
    title= sessionStorage.getItem('courseName');
    nameButtonAction?= 'Get coupon';
    spots: Spot[] = [];
    stampRallyCustomerPrizeRequest: StampRallyCustomerPrizeRequest | undefined;
    stampRallyCustomerPrizeResponse:
        | StampRallyCustomerPrizeResponse
        | undefined;
    stampRallyCustomerAnswersResponse:
        | StampRallyCustomerAnswersResponse
        | undefined;
    isReady = false;
    courseId = '';
    eventId = '';
    shopDid = '';
    spotQRCodeId = '';
    isCompleted = false;
    isClaimed = false;
    isAvailable = false;
    translation?: Translation

    constructor(
        private spotService: SpotService,
        private stampRallyCustomerAnswerService: StampRallyCustomerAnswerService,
        private stampRallyCustomerPrizeService: StampRallyCustomerPrizeService,
        private activeRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.loadTranslation();
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'] === 'true'? true: false;
        this.loadSpot(this.courseId);
        this.loadStampRallys(this.eventId, this.courseId);
        this.onCheckFinish();
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.title = sessionStorage.getItem('courseName');
            this.nameButtonAction = this.translation?.getCouponButton;
        }
    }

    loadSpot(courseId: string): void {
        this.spotService.getSpots(courseId).subscribe((result) => {
            this.spots = result.spots;
        });
    }

    loadStampRallys(eventId: string, courseId: string) {
        this.stampRallyCustomerAnswerService.getStampRallyCustomerAnswer(eventId, courseId)
            .subscribe((result) => {
                this.stampRallyCustomerAnswersResponse = result;
                this.isCompleted = result.stamp_rally_course_status === "COMPLETED" ? true : false;
                this.isReady = true;
            });
    }

    onCheckFinish() {
        this.stampRallyCustomerPrizeService.getStampRallyCustomerPrize(this.eventId)
            .subscribe((result) => {
                this.stampRallyCustomerPrizeResponse = result;
                this.isClaimed = this.stampRallyCustomerPrizeResponse.claimed_stamp_rally_customer_prize;
            });
    }

    checkSpotFinish(spotId: string): boolean {
        if (this.stampRallyCustomerAnswersResponse != undefined) {
            const check =
                this.stampRallyCustomerAnswersResponse.stamp_rally_customer_spots.find(
                    (x) => x.spot_id == spotId
                );
            if (check && check.spot_status === 'COMPLETED') {
                return true;
            }
        }
        return false;
    }

    backClick() {
        const url = `/stamp-rally-shop-voucher/course/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}`;
        this.router.navigate([url]);
    }

    selectSpotMapClick(spotId: string, spotName: string) {
        sessionStorage.setItem('spotName', spotName)
        const url = `/stamp-rally-shop-voucher/spot/map/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/spot/${spotId}/is-available/${this.isAvailable}`;
        this.router.navigate([url]);
    }

    questionClick(spotId: string, spotName: string, spotQRCodeId: string) {
        sessionStorage.setItem('spotName', spotName)
        if (this.checkSpotFinish(spotId)) {
            const url = `/stamp-rally-shop-voucher/question/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/spot/${spotId}/is-available/${this.isAvailable}`;
            this.router.navigate([url]);
        } else {
            const url = `/stamp-rally-shop-voucher/qr-code/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/spot/${spotId}/spot-qr-code/${spotQRCodeId}/is-available/${this.isAvailable}/route/question`;
            this.router.navigate([url]);
        }
    }

    onPrize() {
        if (!this.isClaimed && this.isCompleted && this.isAvailable) {
            const url = `/stamp-rally-shop-voucher/qr-code/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/is-available/${this.isAvailable}/route/prize`;
            this.router.navigate([url]);
        }
    }
}
