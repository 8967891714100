<div class="page">
    <div class="header">
        <div class="centered-title">
            <span class="title-text">{{ title }}</span>
        </div>
    </div>
    <div class="content">
        <div class="centered-content">
            <img
                class="img-mat-card-image"
                src="/assets/thank_you_confeti.png"
            />
        </div>
    </div>
    <div class="footer">
        <button [class]="(isCompleted)  ? 'gotch-button' : 'gotch-button-disabled'" (click)="onClose()">
            {{ nameButtonAction }}
        </button>
    </div>
</div>
