import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { StampRallyNetGameSkillResponse } from '../../models/stamp-rally-net-game-skill/stamp-rally-net-game-skill';

const stampRallyNetGameSkillGetUrl = environment.centerServer.domain + '/gotch-event/v1/stamp-rally-net-game-skill/random/{language}';
const languageToken = '{language}';

@Injectable({
    providedIn: 'root',
})
export class StampRallyNetGameSkillService {
    constructor(private readonly http: HttpClient) { }

    getRandomSkill(): Observable<StampRallyNetGameSkillResponse> {
        const getURL = stampRallyNetGameSkillGetUrl
            .replace(languageToken, Functions.getDefaultLanguage())
        return this.http.get<StampRallyNetGameSkillResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
