import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { CloseComponent } from './components/close/close.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
// Stamp Rally
import { EventStampRallyComponent } from './components/stamp-rally/event-detail/event-stamp-rally.component';
import { CourseComponent } from './components/stamp-rally/course/course.component';
import { SpotComponent } from './components/stamp-rally/spot/spot.component';
import { QuestionComponent } from './components/stamp-rally/question/question.component';
import { MapsComponent } from './components/stamp-rally/maps/maps.component';
import { PrizeComponent } from './components/stamp-rally/prize/prize.component';
// Lottery
import { EventLotteryComponent } from './components/lottery/event-detail/event-lottery.component';
import { DrawLotteryComponent } from './components/lottery/draw-lottery/draw-lottery.component';
import { DrawLotteryResultComponent } from './components/lottery/draw-lottery-result/draw-lottery-result.component';
// Coupon
import { EventCouponComponent } from './components/coupon/event-detail/event-coupon.component';
import { CouponPrizeComponent } from './components/coupon/coupon-prize/coupon-prize.component';
// Free Voucher
import { EventFreeVoucherComponent } from './components/free-voucher/event-detail/event-free-voucher.component';
// Stamp Rally Paper Voucher
import { EventDetailPaperVoucherComponent } from './components/stamp-rally-paper-voucher/event-detail/event-detail-paper-voucher.component';
import { CoursePaperVoucherComponent } from './components/stamp-rally-paper-voucher/course/course-paper-voucher.component';
import { SpotPaperVoucherComponent } from './components/stamp-rally-paper-voucher/spot/spot-paper-voucher.component';
import { QrCodePaperVoucherComponent } from './components/stamp-rally-paper-voucher/qr-code/qr-code-paper-voucher.component';
import { MapsPaperVoucherComponent } from './components/stamp-rally-paper-voucher/maps/maps-paper-voucher.component';
import { QuestionPaperVoucherComponent } from './components/stamp-rally-paper-voucher/question/question-paper-voucher.component';
import { ThankYouPaperVoucherComponent } from './components/stamp-rally-paper-voucher/thank-you/thank-you-paper-voucher.component';
// Stamp Rally Shop Voucher
import { EventDetailShopVoucherComponent } from './components/stamp-rally-shop-voucher/event-detail/event-detail-shop-voucher.component';
import { CourseShopVoucherComponent } from './components/stamp-rally-shop-voucher/course/course-shop-voucher.component';
import { SpotShopVoucherComponent } from './components/stamp-rally-shop-voucher/spot/spot-shop-voucher.component';
import { QrCodeShopVoucherComponent } from './components/stamp-rally-shop-voucher/qr-code/qr-code-shop-voucher.component';
import { MapsShopVoucherComponent } from './components/stamp-rally-shop-voucher/maps/maps-shop-voucher.component';
import { QuestionShopVoucherComponent } from './components/stamp-rally-shop-voucher/question/question-shop-voucher.component';
import { PrizeShopVoucherComponent } from './components/stamp-rally-shop-voucher/prize/prize-shop-voucher.component';
// Stamp Rally - Net Game - Coupon
import { EventDetailNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/event-detail/event-detail-net-game-coupon.component';
import { CourseNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/course/course-net-game-coupon.component';
import { QrCodeNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/qr-code/qr-code-net-game-coupon.component';
import { MapsNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/maps/maps-net-game-coupon.component';
import { SpotNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/spot/spot-net-game-coupon.component';
import { QuestionNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/question/question-net-game-coupon.component';
import { DrawSkillNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/draw-skill/draw-skill-net-game-coupon.component';
import { DrawLotteryNetGameCouponComponent } from './components/stamp-rally-net-game-coupon/draw-lottery/draw-lottery-net-game-coupon.component';
import { DrawLotteryNetGameResultCouponComponent } from './components/stamp-rally-net-game-coupon/draw-lottery-result/draw-lottery-net-game-result-coupon.component';

const routes: Routes = [
    { path: '', component: MainComponent },
    // Close
    {
        path: 'route/close',
        component: CloseComponent,
    },
    // stamp-rally: COUPON
    {
        path: 'stamp-rally/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: EventStampRallyComponent,
    },
    {
        path: 'stamp-rally/course/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: CourseComponent,
    },
    {
        path: 'stamp-rally/spot/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable',
        component: SpotComponent,
    },
    {
        path: 'stamp-rally/spot/map/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable',
        component: MapsComponent,
    },
    {
        path: 'stamp-rally/question/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable',
        component: QuestionComponent,
    },
    {
        path: 'stamp-rally/prize/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable',
        component: PrizeComponent,
    },
    // lottery: COUPON
    {
        path: 'lottery/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: EventLotteryComponent,
    },
    {
        path: 'lottery/draw-lottery/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: DrawLotteryComponent,
    },
    {
        path: 'lottery/draw-lottery-result',
        component: DrawLotteryResultComponent,
    },
    // Coupon
    {
        path: 'coupon/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: EventCouponComponent,
    },
    {
        path: 'coupon/prize/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: CouponPrizeComponent,
    },
    // QR Code - COUPON
    {
        path: 'qr-code/event-type/:eventType/event/:eventId/shop-did/:shopDid/route/:route',
        component: QrCodeComponent,
    },
    {
        path: 'qr-code/event-type/:eventType/event/:eventId/shop-did/:shopDid/is-available/:isAvailable/route/:route',
        component: QrCodeComponent,
    },
    {
        path: 'qr-code/event-type/:eventType/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable/route/:route',
        component: QrCodeComponent,
    },
    {
        path: 'qr-code/event-type/:eventType/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable/route/:route',
        component: QrCodeComponent,
    },
    // QR Code - Voucher
    {
        path: 'qr-code/event-type/:eventType/event/:eventId/shop-did/:shopDid/route/:route',
        component: QrCodeComponent,
    },
    {
        path: 'qr-code/event-type/:eventType/event/:eventId/shop-did/:shopDid/is-available/:isAvailable/route/:route',
        component: QrCodeComponent,
    },
    {
        path: 'qr-code/event-type/:eventType/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable/route/:route',
        component: QrCodeComponent,
    },
    {
        path: 'qr-code/event-type/:eventType/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable/route/:route',
        component: QrCodeComponent,
    },
    // Free Voucher
    {
        path: 'free-voucher/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: EventFreeVoucherComponent,
    },
    // stamp-rally-paper-voucher: PAPER VOUCHER
    {
        path: 'stamp-rally-paper-voucher/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: EventDetailPaperVoucherComponent,
    },
    {
        path: 'stamp-rally-paper-voucher/course/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: CoursePaperVoucherComponent,
    },
    {
        path: 'stamp-rally-paper-voucher/spot/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable',
        component: SpotPaperVoucherComponent,
    },
    {
        path: 'stamp-rally-paper-voucher/spot/map/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable',
        component: MapsPaperVoucherComponent,
    },
    {
        path: 'stamp-rally-paper-voucher/question/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable',
        component: QuestionPaperVoucherComponent,
    },
    {
        path: 'stamp-rally-paper-voucher/thank-you/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable',

        component: ThankYouPaperVoucherComponent,
    },
    {
        path: 'stamp-rally-paper-voucher/qr-code/event/:eventId/shop-did/:shopDid/is-available/:isAvailable/route/:route', // from Event details
        component: QrCodePaperVoucherComponent,
    },
    {
        path: 'stamp-rally-paper-voucher/qr-code/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/spot-qr-code/:spotQRCodeId/is-available/:isAvailable/route/:route', // from spot
        component: QrCodePaperVoucherComponent,
    },
    {
        path: 'stamp-rally-paper-voucher/qr-code/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable/route/:route', // from spot
        component: QrCodePaperVoucherComponent,
    },
    // stamp-rally-shop-voucher: SHOP VOUCHER
    {
        path: 'stamp-rally-shop-voucher/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: EventDetailShopVoucherComponent,
    },
    {
        path: 'stamp-rally-shop-voucher/course/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: CourseShopVoucherComponent,
    },
    {
        path: 'stamp-rally-shop-voucher/spot/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable',
        component: SpotShopVoucherComponent,
    },
    {
        path: 'stamp-rally-shop-voucher/spot/map/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable',
        component: MapsShopVoucherComponent,
    },
    {
        path: 'stamp-rally-shop-voucher/question/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable',
        component: QuestionShopVoucherComponent,
    },
    {
        path: 'stamp-rally-shop-voucher/prize/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable',
        component: PrizeShopVoucherComponent,
    },
    {
        path: 'stamp-rally-shop-voucher/qr-code/event/:eventId/shop-did/:shopDid/is-available/:isAvailable/route/:route', // from Event details
        component: QrCodeShopVoucherComponent,
    },
    {
        path: 'stamp-rally-shop-voucher/qr-code/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/spot-qr-code/:spotQRCodeId/is-available/:isAvailable/route/:route', // from spot
        component: QrCodeShopVoucherComponent,
    },
    {
        path: 'stamp-rally-shop-voucher/qr-code/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable/route/:route', // from spot
        component: QrCodeShopVoucherComponent,
    },
    // stamp-rally-net-game-coupon: NET GAME COUPON
    {
        path: 'stamp-rally-net-game-coupon/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: EventDetailNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/course/event/:eventId/shop-did/:shopDid/is-available/:isAvailable',
        component: CourseNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/spot/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable',
        component: SpotNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/spot/map/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable',
        component: MapsNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/question/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/is-available/:isAvailable',
        component: QuestionNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/qr-code/event/:eventId/shop-did/:shopDid/is-available/:isAvailable/route/:route', // from Event details and draw lottery
        component: QrCodeNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/qr-code/event/:eventId/shop-did/:shopDid/course/:courseId/spot/:spotId/spot-qr-code/:spotQRCodeId/is-available/:isAvailable/route/:route', // from spot
        component: QrCodeNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/draw-skill/event/:eventId/shop-did/:shopDid/course/:courseId/is-available/:isAvailable',
        component: DrawSkillNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/draw-lottery/event/:eventId/shop-did/:shopDid/course/:courseId/skill-type/:skillType/is-available/:isAvailable',
        component: DrawLotteryNetGameCouponComponent,
    },
    {
        path: 'stamp-rally-net-game-coupon/draw-lottery-result',
        component: DrawLotteryNetGameResultCouponComponent,
    },  
    // Wildcard
    { path: '**', component: PageNotFoundComponent }, // Wildcard route for a 404 page. Must be the last route url!!!
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
