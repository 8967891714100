import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class Functions {

    public static getDefaultLanguage(): string {
        const language = sessionStorage.getItem('language');
        if (language) {
            return language;
        }
        return 'en';
    }

    public static getDefaultHeader(): { headers: HttpHeaders; } {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': environment.centerServer.apiKey,
            })
        }
    }

    public static getDefaultHeaderWithBearerToken(): { headers: HttpHeaders; } {
        const access_token = sessionStorage.getItem('access_token');
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': environment.centerServer.apiKey,
                'Authorization': access_token? access_token: '',
            })
        }
    }

    public static isMobile(): boolean {
        return window.innerWidth < 600;
    }
}
