/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Functions } from 'src/app/core/helpers/functions';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { DrawLottery } from 'src/app/core/models/lottery/draw-lottery';
import { LotteryPrize } from 'src/app/core/models/lottery/lottery-prize';
import { LotteryPrizeResponse } from 'src/app/core/models/lottery/lottery-prize';
import { LotteryService } from 'src/app/core/services/ge-lottery-customer-prize-coupon/ge-lottery-customer-prize-coupon.service';

@Component({
    selector: 'app-draw-lottery',
    templateUrl: './draw-lottery.component.html',
    styleUrls: ['./draw-lottery.component.css'],
})
export class DrawLotteryComponent implements OnInit {
    title?= 'Event';
    nameButtonAction?= 'Draw Lottety';
    prizeName = '';
    maybeNextTimeYouWillWin? = 'Maybe next time you will win!';
    lotteryPrize: LotteryPrize | undefined;
    lotteryPrizeResponse: LotteryPrizeResponse | undefined;
    submitted = false;
    drawnLottety = false;
    winLottety = false;
    loseLottety = false;
    eventId = '';
    shopDid = '';
    isAvailable = '';
    translation?: Translation

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private lotteryService: LotteryService
    ) { }

    ngOnInit(): void {
        this.loadTranslation();
        sessionStorage.setItem(
            'lottery_prize',
            ''
        );
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.title = this.translation?.eventTitle;
            this.nameButtonAction = this.translation?.drawLotteryButton;
        }
    }

    onDrawLotterey() {
        this.submitted = true;
        const sortVideo: any = document.getElementById('load');
        sortVideo.play();
        setTimeout(() => {
            this.onPostDrawLottery();
        }, 5000);
    }

    onPostDrawLottery() {
        const drawLottery: DrawLottery = {
            event_id: this.eventId,
            language: Functions.getDefaultLanguage(),
        };
        this.lotteryService.postDrawLottery(drawLottery).subscribe((result) => {
            this.lotteryPrizeResponse = result;
            if (this.lotteryPrizeResponse != undefined) {
                this.drawnLottety = true;
                if (this.lotteryPrizeResponse.lottery_status == 'WIN') {
                    this.title = this.translation?.youWinPrizeTitle;
                    this.prizeName = this.lotteryPrizeResponse.lottery_prize.prize_name;
                    this.nameButtonAction = this.translation?.scanQRCodeToClaimYourPrizeButton;
                    this.winLottety = true;
                    const lotteryPrize = {
                        type: 'LOTTERY',
                        ticketType: 'COUPON',
                        lotteryStatus: 'WIN',
                        shopGroupId: this.lotteryPrizeResponse.lottery_prize.shop_group_id,
                        shopDid: this.lotteryPrizeResponse.lottery_prize.shop_did,
                        masterCouponId: this.lotteryPrizeResponse.lottery_prize.master_coupon_id,
                        couponAmount: this.lotteryPrizeResponse.lottery_prize.coupon_amount,
                    };
                    sessionStorage.setItem(
                        'lottery_prize',
                        JSON.stringify(lotteryPrize)
                    );
                    const url = `/lottery/draw-lottery-result`;
                    this.router.navigate([url]);            
                } else if (this.lotteryPrizeResponse.lottery_status == 'LOSE') {
                    this.title = this.translation?.notThisTimeTitle;
                    this.nameButtonAction = this.translation?.finishButton;
                    this.maybeNextTimeYouWillWin = this.translation?.maybeNextTimeYouWillWin;
                    this.loseLottety = true;
                    const lotteryPrize = {
                        type: 'LOTTERY',
                        ticketType: 'COUPON',
                        lotteryStatus: 'LOSE',
                    };
                    sessionStorage.setItem(
                        'lottery_prize',
                        JSON.stringify(lotteryPrize)
                    );
                    const url = `/lottery/draw-lottery-result`;
                    this.router.navigate([url]);
                }
            } else {
                this.submitted = false;
            }
        });
    }
}
