import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import {
    StampRallyCustomerAnswersPatchRequest,
    StampRallyCustomerAnswersPostRequest,
    StampRallyCustomerAnswersResponse,
    StampRallyCustomerAnswersSaveResponse,
} from '../../models/stamp-rally-customer-answer/stamp-rally-customer-answer';

const stampRallyApiUrl = environment.centerServer.domain + '/gotch-event/v1/stamp-rally-customer-answer/event/{eventId}/course/{courseId}';
const stampRallyPostUrl = environment.centerServer.domain + '/gotch-event/v1/stamp-rally-customer-answer';
const stampRallyPatchUrl = environment.centerServer.domain + '/gotch-event/v1/stamp-rally-customer-answer/{stamp_rally_customer_answer_id}';
const languageToken = '{language}';
const eventIdToken = '{eventId}';
const courseIdToken = '{courseId}';
const stampRallyCustomerAnswerIdToken = '{stamp_rally_customer_answer_id}';

@Injectable({
    providedIn: 'root',
})
export class StampRallyCustomerAnswerService {
    constructor(private readonly http: HttpClient) {}

    getStampRallyCustomerAnswer(
        eventId: string,
        courseId: string
    ): Observable<StampRallyCustomerAnswersResponse> {
        const getURL = stampRallyApiUrl
            .replace(languageToken, Functions.getDefaultLanguage())
            .replace(eventIdToken, eventId)
            .replace(courseIdToken, courseId);
        return this.http.get<StampRallyCustomerAnswersResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }

    postQuestion(
        request: StampRallyCustomerAnswersPostRequest
    ): Observable<StampRallyCustomerAnswersSaveResponse> {
        return this.http.post<StampRallyCustomerAnswersSaveResponse>(
            stampRallyPostUrl,
            request,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }

    patchQuestion(
        request: StampRallyCustomerAnswersPatchRequest,
        stampRallyCustomerAnswer: string
    ): Observable<StampRallyCustomerAnswersSaveResponse> {
        const getURL = stampRallyPatchUrl
            .replace(languageToken, Functions.getDefaultLanguage())
            .replace(stampRallyCustomerAnswerIdToken, stampRallyCustomerAnswer);
        return this.http.patch<StampRallyCustomerAnswersSaveResponse>(
            getURL,
            request,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
