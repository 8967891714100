import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { SpotsResponse } from '../../models/spot/spot';

const spotsApiUrl = environment.centerServer.domain + '/gotch-event/v1/course/{courseId}/spots/{language}';
const languageToken = '{language}';
const courseIdToken = '{courseId}';

@Injectable({
    providedIn: 'root',
})
export class SpotService {
    constructor(private readonly http: HttpClient) {}

    getSpots(courseId: string): Observable<SpotsResponse> {
        const getURL = spotsApiUrl
            .replace(languageToken, Functions.getDefaultLanguage())
            .replace(courseIdToken, courseId);
        return this.http.get<SpotsResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
