/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { StampRallyCustomerAnswerService } from 'src/app/core/services/stamp-rally-customer-answer/stamp-rally-customer-answers.service';
import { QuestionService } from 'src/app/core/services/ge-question/ge-question.service';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { Question } from 'src/app/core/models/question/question';
import { StampRallyCustomerAnswersResponse, StampRallyCustomerAnswersPostRequest, StampRallyCustomerAnswersPatchRequest } from 'src/app/core/models/stamp-rally-customer-answer/stamp-rally-customer-answer';
import { Answer, AnswersResponse } from 'src/app/core/models/answer/answers';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.css'],
})
export class QuestionComponent implements OnInit {
    title = sessionStorage.getItem('spotName');
    nameButtonAction? = 'Send and complete';
    completeButton? = 'Complete';
    questions: Question[] = [];
    question: Question | undefined;
    stampRallyCustomerAnswersResponse:
        | StampRallyCustomerAnswersResponse
        | undefined;
    stampRallyCustomerAnswersPostRequest:
        | StampRallyCustomerAnswersPostRequest
        | undefined;
    stampRallyCustomerAnswersPatchRequest:
        | StampRallyCustomerAnswersPatchRequest
        | undefined;
    answersResponse: AnswersResponse | undefined;
    answerModel: Answer | undefined;
    answerTitle?: string;
    answerCorrect = false;
    questionNumber = 0;
    isReady = false;
    isFinish = false;
    courseId = '';
    eventId = '';
    shopDid = '';
    spotId = '';
    question_id = '';
    answer_id = '';
    answerSelect: string | undefined;
    answerResponse: string | undefined;
    isUpdate = false;
    isDisabledButton = true;
    stampRallyCustomerAnswerId = '';
    textQuestionFC = new UntypedFormControl();
    translation?: Translation;
    correct? = '正解';
    wrong? = '不正解';

    constructor(
        private questionService: QuestionService,
        private stampRallyCustomerAnswerService: StampRallyCustomerAnswerService,
        private activeRoute: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loadTranslation();
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.spotId = this.activeRoute.snapshot.params['spotId'];
        this.onLoad(this.spotId);
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.title = sessionStorage.getItem('spotName');
            this.nameButtonAction = this.translation?.sendAndCompleteButton;
            this.completeButton = this.translation?.completeButton;
            this.correct = this.translation?.correct;
            this.wrong = this.translation?.wrong;

        }
    }

    onLoad(spotId: string) {
        this.questionService.getQuestions(spotId).subscribe((result) => {
            if (result) {
                this.questions = result.questions;
                if (this.questions)
                    this.onLoadAnswers(this.eventId, this.courseId);
            }
        });
    }

    onLoadAnswers(eventId: string, courseId: string) {
        this.stampRallyCustomerAnswerService
            .getStampRallyCustomerAnswer(eventId, courseId)
            .subscribe((result) => {
                if (result) {
                    this.stampRallyCustomerAnswersResponse = result;
                    if (this.stampRallyCustomerAnswersResponse)
                        this.onLoadQuestions(this.questionNumber);
                }
            });
    }

    onLoadQuestions(number: number) {
        this.isFinish = this.questions.length - this.questionNumber === 0;
        if (this.questionNumber < this.questions.length) {
            this.question = this.questions[number];
            if (this.question.type === 'TEXT') {
                this.answer_id = this.question.answers[0].answer_id;
            }
            const answers =
                this.stampRallyCustomerAnswersResponse?.stamp_rally_customer_answers.find(
                    (x) => x.question_id == this.question?.question_id
                );
            if (answers) {
                this.stampRallyCustomerAnswerId =
                    answers.stamp_rally_customer_answer_id;
                const answer: Answer = {
                    answer_id: answers.answer_id,
                    question_id: this.question.question,
                    answer: answers.answer_comment,
                };
                this.isUpdate = true;
                this.isDisabledButton = false;
                this.answerSelect = answers.answer_id;
                this.answerResponse = answers.answer_comment;
                this.onCheckRadio(answer);
            } else {
                this.isUpdate = false;
                this.isDisabledButton = true;
            }
            this.question_id = this.question.question_id;
            this.isReady = true;
        }
    }

    onCheckRadio(answer: Answer) {
        if (answer.is_correct) {
            this.isDisabledButton = false;
            this.answerTitle = answer.answer;
        } else if (!answer.is_correct) {
            this.isDisabledButton = true;
            this.answerTitle = answer.answer;
        } else {
            this.isDisabledButton = true;
            this.answerTitle = answer.answer;
        }

        this.answerSelect = answer.answer_id;

        this.answerModel = answer;
        if (this.isUpdate) {
            const stampRallyCustomerAnswersPatchRequest = {
                answer_id: answer.answer_id,
                answer_comment: answer.answer,
            };
            this.stampRallyCustomerAnswersPatchRequest =
                stampRallyCustomerAnswersPatchRequest;
        } else {
            const stampRallyCustomerAnswerRequest: StampRallyCustomerAnswersPostRequest =
                {
                    event_id: this.eventId,
                    course_id: this.courseId,
                    spot_id: this.spotId,
                    question_id: answer.question_id,
                    answer_id: answer.answer_id,
                    answer_comment: answer.answer,
                };
            this.stampRallyCustomerAnswersPostRequest =
                stampRallyCustomerAnswerRequest;
        }
    }

    onCommentChange() {
        const answer: Answer = {
            answer: this.textQuestionFC.value,
            answer_id: this.answer_id,
            question_id: this.question_id,
        };
        this.isDisabledButton = false;
        this.answerModel = answer;
        if (this.isUpdate) {
            const stampRallyCustomerAnswersPatchRequest = {
                answer_id: this.answer_id,
                answer_comment: answer.answer,
            };
            this.stampRallyCustomerAnswersPatchRequest =
                stampRallyCustomerAnswersPatchRequest;
        } else {
            const stampRallyCustomerAnswerRequest = {
                event_id: this.eventId,
                course_id: this.courseId,
                spot_id: this.spotId,
                question_id: this.question_id,
                answer_id: this.answer_id,
                answer_comment: answer.answer,
            };
            this.stampRallyCustomerAnswersPostRequest =
                stampRallyCustomerAnswerRequest;
        }
    }

    onSend() {
        if (this.questionNumber <= this.questions.length) {
            if (this.answerModel != undefined) {
                if (this.isUpdate) {
                    this.onUpdate();
                } else {
                    this.onSave();
                }
                this.questionNumber = this.questionNumber + 1;
                this.onLoadQuestions(this.questionNumber);
            }
        }
    }

    onSave() {
        const stampRallyCustomerAnswersPostRequest: StampRallyCustomerAnswersPostRequest =
            {
                event_id: this.stampRallyCustomerAnswersPostRequest?.event_id
                    ? this.stampRallyCustomerAnswersPostRequest?.event_id
                    : '',
                course_id: this.stampRallyCustomerAnswersPostRequest?.course_id
                    ? this.stampRallyCustomerAnswersPostRequest?.course_id
                    : '',
                spot_id: this.stampRallyCustomerAnswersPostRequest?.spot_id
                    ? this.stampRallyCustomerAnswersPostRequest?.spot_id
                    : '',
                question_id: this.stampRallyCustomerAnswersPostRequest
                    ?.question_id
                    ? this.stampRallyCustomerAnswersPostRequest?.question_id
                    : '',
                answer_id: this.stampRallyCustomerAnswersPostRequest?.answer_id
                    ? this.stampRallyCustomerAnswersPostRequest?.answer_id
                    : '',
                answer_comment: this.stampRallyCustomerAnswersPostRequest
                    ?.answer_comment
                    ? this.stampRallyCustomerAnswersPostRequest?.answer_comment
                    : '',
            };
        this.stampRallyCustomerAnswerService
            .postQuestion(stampRallyCustomerAnswersPostRequest)
            .subscribe(() => {
                if (this.isFinish) this.onBackClick();
            });
    }

    onUpdate() {
        const stampRallyCustomerAnswersPatchRequest: StampRallyCustomerAnswersPatchRequest =
            {
                answer_id: this.stampRallyCustomerAnswersPatchRequest?.answer_id
                    ? this.stampRallyCustomerAnswersPatchRequest?.answer_id
                    : '',
                answer_comment: this.stampRallyCustomerAnswersPatchRequest
                    ?.answer_comment
                    ? this.stampRallyCustomerAnswersPatchRequest?.answer_comment
                    : '',
            };
        this.stampRallyCustomerAnswerService
            .patchQuestion(
                stampRallyCustomerAnswersPatchRequest,
                this.stampRallyCustomerAnswerId
            )
            .subscribe(() => {
                if (this.isFinish) this.onBackClick();
            });
    }

    onBackClick() {
        const url = `/stamp-rally/spot/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/is-available/true`;
        this.router.navigate([url]);
    }
}
