/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeEvent } from 'src/app/core/models/ge-event/ge-event';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { EventService } from 'src/app/core/services/ge-event/ge-event.service';
declare function sendData(data: string): string;

@Component({
    selector: 'app-event',
    templateUrl: './event-free-voucher.component.html',
    styleUrls: ['./event-free-voucher.component.css'],
})
export class EventFreeVoucherComponent implements OnInit {
    title?= 'Event';
    nameButtonAction? = 'Continue previous course';
    scheduleText?= 'Schedule';
    isAvailable = '';
    eventId = '';
    shopDid = '';
    geEvent?: GeEvent;
    isReady = false;
    translation?: Translation
    isVisible = true;

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private eventService: EventService,
    ) { }

    ngOnInit(): void {
        this.loadTranslation();
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
        this.loadDetails(this.eventId);
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.title = this.translation?.eventTitle;
            this.nameButtonAction = this.translation?.continuePreviousCourseButton;
            this.scheduleText = this.translation?.scheduleText;
        }
    }

    private loadDetails(eventId: string) {
        if (eventId) {
            this.eventService.getEvent(eventId).subscribe((result) => {
                this.geEvent = result.event;
                // Get the current date
                const currentDate = new Date().getTime();
                // check if the event current date is less than the event start date
                const startDate = new Date(this.geEvent?.start_date).getTime();
                const endDate = new Date(this.geEvent?.end_date).getTime();
                // Check if the current date is within the event date range
                this.isVisible = currentDate >= startDate && currentDate <= endDate;
                this.isReady = true;
            });
        }
    }

    onPinCode() {
        const pinCode = {
            type: 'FREE-VOUCHER',
            ticketType: 'SHOP-VOUCHER',
        };
        sendData(JSON.stringify(pinCode));
    }

    onClose() {
        const close = {
            close: true
        }
        sendData(JSON.stringify(close));
    }
}
