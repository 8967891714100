import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Functions } from 'src/app/core/helpers/functions';
import { StampRallyCustomerPrizeShopVoucherResponse } from 'src/app/core/models/stamp-rally-customer-prize-shop-voucher/stamp-rally-customer-prize-shop-voucher';
import { StampRallyCustomerPrizeRequest } from 'src/app/core/models/stamp-rally-customer-prize/stamp-rally-customer-prize';
import { StampRallyCustomerPrizeShopVoucherService } from 'src/app/core/services/stamp-rally-customer-prize-shop-voucher/stamp-rally-customer-prize-shop-voucher.service';
declare function sendData(data: string): string;

@Component({
    selector: 'app-prize',
    templateUrl: './prize-shop-voucher.component.html',
    styleUrls: ['./prize-shop-voucher.component.css'],
})
export class PrizeShopVoucherComponent implements OnInit {
    courseId = '';
    eventId = '';
    stampRallyCustomerPrizeShopVoucherResponse: | StampRallyCustomerPrizeShopVoucherResponse | undefined;

    constructor(
        private stampRallyCustomerPrizeShopVoucherService: StampRallyCustomerPrizeShopVoucherService,
        private activeRoute: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.onPrize();
    }

    onPrize() {
        const stampRallyCustomerPrizeRequest: StampRallyCustomerPrizeRequest = {
            course_id: this.courseId,
            event_id: this.eventId,
            language: Functions.getDefaultLanguage(),
        };
        this.stampRallyCustomerPrizeShopVoucherService
            .postShopVoucherPrize(stampRallyCustomerPrizeRequest)
            .subscribe((result) => {
                if (result.statusCode === 1000) {
                    this.stampRallyCustomerPrizeShopVoucherResponse = result;
                    const stampRallyCustomerPrize = {
                        type: 'STAMP-RALLY',
                        ticketType: 'SHOP-VOUCHER',
                        prizeName: this.stampRallyCustomerPrizeShopVoucherResponse.stamp_rally_prize_shop_voucher.prize_name,
                        prizeDescription: this.stampRallyCustomerPrizeShopVoucherResponse.stamp_rally_prize_shop_voucher.prize_description,
                        distribution: this.stampRallyCustomerPrizeShopVoucherResponse.stamp_rally_prize_shop_voucher.distribution,
                        issuerDid: this.stampRallyCustomerPrizeShopVoucherResponse.stamp_rally_prize_shop_voucher.issuer_did,
                        masterVoucherId: this.stampRallyCustomerPrizeShopVoucherResponse.stamp_rally_prize_shop_voucher.master_voucher_id,
                    };
                    sendData(JSON.stringify(stampRallyCustomerPrize));    
                }
            }
        );
    }
}
