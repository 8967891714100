<div class="loader">
    <ng-lottie class="loading" [hidden]="loaded" [options]="options" (animationCreated)="animationCreated()"></ng-lottie>
</div>
<div class="page" [hidden]="!loaded">
    <div class="content">
        <div class="title">
            {{ skill?.name }}
        </div>
        <img class="skill" src="{{ skill?.image_url }}"/>
    </div>
    <div class="footer">
        <button (click)="onDrawLottery()" [disabled]="submitted" [class]="submitted? 'gotch-button-disabled' : 'gotch-button'" >
            {{ nameButtonAction }}
        </button>
    </div>
</div>
