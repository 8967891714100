/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeEvent } from 'src/app/core/models/ge-event/ge-event';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { EventService } from 'src/app/core/services/ge-event/ge-event.service';
declare function sendData(data: string): string;

@Component({
    selector: 'app-event',
    templateUrl: './event-coupon.component.html',
    styleUrls: ['./event-coupon.component.css'],
})
export class EventCouponComponent implements OnInit {
    title?= 'Event';
    nameButtonAction? = 'Scan QR Code to start';
    scheduleText?= 'Schedule';
    isAvailable = '';
    eventId = '';
    shopDid = '';
    geEvent?: GeEvent;
    isReady = false;
    translation?: Translation
    isScanQRCode = true;

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private eventService: EventService,
    ) { }

    ngOnInit(): void {
        this.loadTranslation();
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
        this.loadDetails(this.eventId);
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.title = this.translation?.eventTitle;
            this.nameButtonAction = this.translation?.scanQRCodeButton;
            this.scheduleText = this.translation?.scheduleText;
        }
    }

    private loadDetails(eventId: string) {
        if (eventId) {
            this.eventService.getEvent(eventId).subscribe((result) => {
                this.geEvent = result.event;
                this.isReady = true;
            });
        }
    }

    goToQRCode() {
        const url = `/qr-code/event-type/coupon/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}/route/coupon`;
        this.router.navigate([url]);
    }

    onClose() {
        const close = {
            close: true
        }
        sendData(JSON.stringify(close));
    }
}
