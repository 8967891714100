/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RefreshTokenResponse } from '../../models/authentication/authentication-response';

const apiUrlRefreshToken =
    environment.centerServer.domain + '/customer/refresh';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(private readonly http: HttpClient) {}

    getAuthStatus(): boolean {
        return sessionStorage.getItem('access_token') &&
            sessionStorage.getItem('refresh_token')
            ? true
            : false;
    }

    getAuthToken(): string {
        return sessionStorage.getItem('access_token') ?? '';
    }

    refreshToken(): Observable<any> {
        const refresh_token = sessionStorage.getItem('refresh_token');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': environment.centerServer.apiKey,
                Authorization: refresh_token ? refresh_token : '',
            }),
        };
        return this.http.post<RefreshTokenResponse>(
            apiUrlRefreshToken,
            {},
            httpOptions
        );
    }
}
