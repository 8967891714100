import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { DrawLottery } from '../../models/lottery/draw-lottery';
import { LotteryPrizeResponse } from '../../models/lottery/lottery-prize';

const lotteryApiUrl = environment.centerServer.domain + '/gotch-event/v1/lotteries/draw-lottery';

@Injectable({
    providedIn: 'root',
})
export class LotteryService {
    constructor(private readonly http: HttpClient) {}

    postDrawLottery(
        drawLottery: DrawLottery
    ): Observable<LotteryPrizeResponse> {
        return this.http.post<LotteryPrizeResponse>(
            lotteryApiUrl,
            drawLottery,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
