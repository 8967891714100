/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { AnimationOptions } from 'ngx-lottie';
import { Functions } from 'src/app/core/helpers/functions';
import {
    LotteryPrize,
    StampRallyNetGameLotteryCouponRequest,
    StampRallyNetGameLotteryCouponResponse,
} from 'src/app/core/models/stamp-rally-net-game-lottery-coupon/stamp-rally-net-game-lottery-coupon';
import { StampRallyNetGameLotteryCouponService } from 'src/app/core/services/stamp-rally-net-game-lottery-coupon/stamp-rally-net-game-lottery-coupon.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalComponent } from 'src/app/shared/dialog-modal/dialog-modal..component';

declare function sendData(data: string): string;

@Component({
    selector: 'app-draw-lottery-net-game-coupon',
    templateUrl: './draw-lottery-net-game-coupon.component.html',
    styleUrls: ['./draw-lottery-net-game-coupon.component.css'],
})
export class DrawLotteryNetGameCouponComponent implements OnInit {
    loaded = false;
    submitted = false;
    nameButtonAction? = '';
    eventId = '';
    shopDid = '';
    courseId = '';
    skillType = '';
    isAvailable = '';
    translation: Translation | undefined;
    lotteryPrize: LotteryPrize | undefined;
    result: StampRallyNetGameLotteryCouponResponse | undefined;
    drawnLottety = false;
    winLottety = false;
    loseLottety = false;
    lotteryMessage = '';
    lotteryStatus = '';
    error = false;

    options: AnimationOptions = {
        path: '/assets/loading.json',
    };

    animationCreated(): void {
        setTimeout(() => {
            this.loaded = true;
            this.drawLottery();
        }, 2500);
    }

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private stampRallyNetGameLotteryCouponService: StampRallyNetGameLotteryCouponService,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        sessionStorage.setItem('lottery_prize', '');
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.skillType = this.activeRoute.snapshot.params['skillType'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
        this.loadTranslation();
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
        }
    }

    private drawLottery() {
        const stampRallyNetGameLotteryCouponRequest: StampRallyNetGameLotteryCouponRequest =
            {
                event_id: this.eventId,
                course_id: this.courseId,
                skill_type: this.skillType,
                language: Functions.getDefaultLanguage(),
            };
        this.stampRallyNetGameLotteryCouponService
            .postDrawLottery(stampRallyNetGameLotteryCouponRequest)
            .subscribe(
                (result) => {
                    this.result = result;
                    if (this.result != undefined) {
                        this.drawnLottety = true;
                        this.lotteryMessage = this.result.lottery_message;
                        this.lotteryStatus = this.result.lottery_status;
                        if (this.lotteryStatus === 'WIN') {
                            this.winLottety = true;
                            this.nameButtonAction =
                                this.translation?.getNetGameCoupon;
                            const lotteryPrize = {
                                type: 'STAMP-RALLY-NET-GAME',
                                ticketType: 'COUPON',
                                lotteryStatus: 'WIN',
                                shopGroupId:
                                    this.result.lottery_prize.shop_group_id,
                                shopDid: this.result.lottery_prize.shop_did,
                                masterCouponId:
                                    this.result.lottery_prize.master_coupon_id,
                                couponAmount:
                                    this.result.lottery_prize.coupon_amount,
                            };
                            sessionStorage.setItem(
                                'lottery_prize',
                                JSON.stringify(lotteryPrize)
                            );
                        } else if (this.lotteryStatus === 'LOSE') {
                            this.loseLottety = true;
                            this.nameButtonAction = this.translation?.finishButton;
                            const lotteryPrize = {
                                type: 'STAMP-RALLY-NET-GAME',
                                ticketType: 'COUPON',
                                lotteryStatus: 'LOSE',
                            };
                            sessionStorage.setItem(
                                'lottery_prize',
                                JSON.stringify(lotteryPrize)
                            );
                        }
                    } else {
                        this.submitted = false;
                    }
                },
                (error) => {
                    // Handle error
                    console.error('Error drawing lottery:', error);
                    // You can display an error message to the user or take other actions as needed.
                    this.error = true;
                    this.openDialog("このユーザーはすでに利用済みです。1ユーザー1回のみ利用できます。", 'Ok');
                }
            );
    }

    onFinish() {
        this.submitted = true;
        if (this.winLottety) {
            const url = `/stamp-rally-net-game-coupon/qr-code/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}/route/draw-lottery-result`;
            this.router.navigate([url]);
        } else {
            const close = {
                close: true,
            };
            sendData(JSON.stringify(close));
        }
    }

    openDialog(message: string, action: string) {
        this.dialog.open(DialogModalComponent, {
            data: {
                message,
                action,
            },
        });
    }
}
