import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Functions } from 'src/app/core/helpers/functions';
import { StampRallyCustomerPrizeCouponResponse } from 'src/app/core/models/stamp-rally-customer-prize-coupon/stamp-rally-customer-prize-coupon';
import { StampRallyCustomerPrizeRequest } from 'src/app/core/models/stamp-rally-customer-prize/stamp-rally-customer-prize';
import { StampRallyCustomerPrizeCouponService } from 'src/app/core/services/stamp-rally-customer-prize-coupon/stamp-rally-customer-prize-coupon.service';
declare function sendData(data: string): string;

@Component({
    selector: 'app-prize',
    templateUrl: './prize.component.html',
    styleUrls: ['./prize.component.css'],
})
export class PrizeComponent implements OnInit {
    courseId = '';
    eventId = '';
    stampRallyCustomerPrizeResponse:
        | StampRallyCustomerPrizeCouponResponse
        | undefined;

    constructor(
        private stampRallyCustomerPrizeCouponService: StampRallyCustomerPrizeCouponService,
        private activeRoute: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.onPrize();
    }

    onPrize() {
        const stampRallyCustomerPrizeRequest: StampRallyCustomerPrizeRequest = {
            course_id: this.courseId,
            event_id: this.eventId,
            language: Functions.getDefaultLanguage(),
        };
        this.stampRallyCustomerPrizeCouponService
            .postCouponPrize(stampRallyCustomerPrizeRequest)
            .subscribe((result) => {
                if (result.statusCode === 1000) {
                    this.stampRallyCustomerPrizeResponse = result;
                    const stampRallyCustomerPrize = {
                        type: 'STAMP-RALLY',
                        ticketType: 'COUPON',
                        prizeName: this.stampRallyCustomerPrizeResponse.stamp_rally_prize_coupon.prize_name,
                        prizeDescription: this.stampRallyCustomerPrizeResponse.stamp_rally_prize_coupon.prize_description,
                        shopGroupId: this.stampRallyCustomerPrizeResponse.stamp_rally_prize_coupon.shop_group_id,
                        shopDid: this.stampRallyCustomerPrizeResponse.stamp_rally_prize_coupon.shop_did,
                        masterCouponId: this.stampRallyCustomerPrizeResponse.stamp_rally_prize_coupon.master_coupon_id,
                        couponAmount: this.stampRallyCustomerPrizeResponse.stamp_rally_prize_coupon.coupon_amount,
                    };
                    sendData(JSON.stringify(stampRallyCustomerPrize));    
                }
            }
        );
    }
}
