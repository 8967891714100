<div class="loader">
    <ng-lottie class="loading" [hidden]="loaded" [options]="options" (animationCreated)="animationCreated()"></ng-lottie>
</div>
<div class="page" [hidden]="!loaded">
    <div [hidden]="error">
        <div class="content">
            <div class="lottery-message">
                <span [innerHtml]="this.lotteryMessage"></span>
            </div>
            <div [hidden]="!this.winLottety" class="lottery-prize">
                ヤクルト製品を取得しました
            </div>
            <img class="skill" src="../../../../assets/win-lose.jpeg"/>
        </div>
        <div class="footer">
            <button (click)="onFinish()" [disabled]="submitted" [class]="submitted? 'gotch-button-disabled' : 'gotch-button'" >
                {{ nameButtonAction }}
            </button>
        </div>
    </div>
</div>
