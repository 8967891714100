/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { QrCode } from 'src/app/core/models/qr-code/qr-code';
import { Location } from '@angular/common';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalComponent } from '../../../shared/dialog-modal/dialog-modal..component';
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';

@Component({
    selector: 'app-qr-code-net-game-coupon',
    templateUrl: './qr-code-net-game-coupon.component.html',
    styleUrls: ['./qr-code-net-game-coupon.component.css'],
})
export class QrCodeNetGameCouponComponent implements OnInit {
    qrcodeView = true;
    scannerEnabled = true;
    scanQRCodeTitle? = 'Please, scan the QR Code';
    scannedData = '';
    lotteryId = '';
    qrCode: QrCode | undefined;
    eventType = '';
    eventId = '';
    shopGroupId = '';
    shopDid = '';
    courseId = '';
    spotId = '';
    spotQRCodeId = '';
    route = '';
    isAvailable = '';
    translation?: Translation;
    isAndroid = false
    
    @ViewChild('action')
    action: NgxScannerQrcodeComponent = new NgxScannerQrcodeComponent;

    public config: ScannerQRCodeConfig = {
        // fps: 1000,
        vibrate: 0,
        isBeep: false,
        // decode: 'macintosh',
        deviceActive: 1,
        constraints: { 
          audio: false,
          video: {
            width: window.innerWidth
          }
        } 
    };

    allowedFormats = [
        BarcodeFormat.QR_CODE,
        BarcodeFormat.EAN_13,
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX /*, ...*/,
    ];

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private location: Location,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.eventType = this.activeRoute.snapshot.params['eventType'];
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.spotId = this.activeRoute.snapshot.params['spotId'];
        this.spotQRCodeId = this.activeRoute.snapshot.params['spotQRCodeId'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
        this.route = this.activeRoute.snapshot.params['route'];
        const deviceType = sessionStorage.getItem('deviceType');
        this.isAndroid = deviceType === 'ANDROID';
        this.loadTranslation();
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngAfterContentInit(): void {
        if (this.action) {
            setTimeout(() => 
                this.action.start(), 
            2000);
        }
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.scanQRCodeTitle = this.translation?.scanQRCodeTitle;
        }
    }

    scanSuccessHandler($event: any) {
        this.qrCode = undefined;
        if (this.scannedData === $event) {
            return;
        }
        this.scannedData = $event;
        this.handleData();
    }

    private handleData() {
        if (this.isJSON(this.scannedData)) {
            this.onStampRallyShopVoucher();
        } else {
            this.onSpotQuestionShopVoucherRouterNavigate();
        }
    }

    public onEvent(e: ScannerQRCodeResult[]): void {
        console.log(e);
        if (e.length === 0 || this.scannedData === e[0].value) {
            return;
        }
        this.scannedData = e[0].value;
        this.handleData();
    }

    public handle(action: any, fn: string): void {
        action[fn]().subscribe(console.log, alert);
    }

    onStampRallyShopVoucher() {
        this.qrCode = JSON.parse(this.scannedData);
        if (this.qrCode) {
            if (this.qrCode.shopDid === this.shopDid) {
                this.onStampRallyShopVoucherRouterNavigate()
            } else {
                this.openDialogQrCode('SRSV01:このQRコードは有効ではありません', 'Ok');
            }
        } else {
            this.openDialogQrCode('SRSV02:このQRコードは有効ではありません', 'Ok');
        }
    }

    onStampRallyShopVoucherRouterNavigate() {
        if (this.route == 'course') {
            const url = `/stamp-rally-net-game-coupon/course/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}`;
            this.router.navigate([url]);
        } else if (this.route == 'draw-lottery-result') {
            const url = `/stamp-rally-net-game-coupon/draw-lottery-result`;
            this.router.navigate([url]);
        } else {
            this.openDialogQrCode('SRSV03:このQRコードは有効ではありません...', 'Ok');
        }
    }

    onSpotQuestionShopVoucherRouterNavigate() {
        if (this.spotQRCodeId === this.scannedData) {
            const url = `/stamp-rally-net-game-coupon/question/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/spot/${this.spotId}/is-available/${this.isAvailable}`;
            this.router.navigate([url]); 
        } else {
            this.openDialogQrCode('SRSV04:このQRコードは有効ではありません', 'Ok');
        }
    }

    isJSON(text: string) {
        try {
            return (JSON.parse(text) && !!text);
        } catch {
            return false;
        }
    }

    openDialogQrCode(message: string, action: string) {
        this.dialog.open(DialogModalComponent, {
            data: {
                message,
                action,
            },
        });
    }

    backClicked() {
        this.location.back();
    }
}
