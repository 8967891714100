import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { CoursesResponse } from '../../models/course/course';

const coursesApiUrl = environment.centerServer.domain + '/gotch-event/v1/event/{eventId}/courses/{language}';
const languageToken = '{language}';
const eventIdToken = '{eventId}';

@Injectable({
    providedIn: 'root',
})
export class CourseService {
    constructor(private readonly http: HttpClient) {}

    getCourses(eventId: string): Observable<CoursesResponse> {
        const getURL = coursesApiUrl
            .replace(languageToken, Functions.getDefaultLanguage())
            .replace(eventIdToken, eventId);
        return this.http.get<CoursesResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}