import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { StampRallyCustomerPrizeCouponResponse, StampRallyCustomerPrizeRequest } from '../../models/stamp-rally-customer-prize-coupon/stamp-rally-customer-prize-coupon';

const stampRallyPrizeCouponPostUrl = environment.centerServer.domain + '/gotch-event/v1/stamp-rally-customer-coupon-prize';

@Injectable({
    providedIn: 'root',
})
export class StampRallyCustomerPrizeCouponService {
    constructor(private readonly http: HttpClient) { }

    postCouponPrize(
        request: StampRallyCustomerPrizeRequest
    ): Observable<StampRallyCustomerPrizeCouponResponse> {
        return this.http.post<StampRallyCustomerPrizeCouponResponse>(
            stampRallyPrizeCouponPostUrl,
            request,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
