import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { Spot } from 'src/app/core/models/spot/spot';
import { StampRallyCustomerAnswersResponse } from 'src/app/core/models/stamp-rally-customer-answer/stamp-rally-customer-answer';
import { SpotService } from 'src/app/core/services/ge-spot/ge-spot.service';
import { StampRallyCustomerAnswerService } from 'src/app/core/services/stamp-rally-customer-answer/stamp-rally-customer-answers.service';
import { StampRallyNetGameLotteryCouponService } from 'src/app/core/services/stamp-rally-net-game-lottery-coupon/stamp-rally-net-game-lottery-coupon.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalComponent } from 'src/app/shared/dialog-modal/dialog-modal..component';

@Component({
    selector: 'app-spot-net-game-coupon',
    templateUrl: './spot-net-game-coupon.component.html',
    styleUrls: ['./spot-net-game-coupon.component.css'],
})
export class SpotNetGameCouponComponent implements OnInit {
    title = sessionStorage.getItem('courseName');
    nameButtonAction? = 'Get coupon';
    spots: Spot[] = [];
    stampRallyCustomerAnswersResponse:
        | StampRallyCustomerAnswersResponse
        | undefined;
    courseId = '';
    eventId = '';
    shopDid = '';
    spotQRCodeId = '';
    isCompleted = false;
    isClaimed = false;
    isAvailable = false;
    translation?: Translation;

    constructor(
        private spotService: SpotService,
        private stampRallyCustomerAnswerService: StampRallyCustomerAnswerService,
        private stampRallyNetGameLotteryCouponService: StampRallyNetGameLotteryCouponService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.loadTranslation();
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.isAvailable =
            this.activeRoute.snapshot.params['isAvailable'] === 'true'
                ? true
                : false;
        this.loadSpot(this.courseId);
        this.loadStampRallys(this.eventId, this.courseId);
        this.onCheckFinish();
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.title = sessionStorage.getItem('courseName');
            this.nameButtonAction = this.translation?.getNetGameButton;
        }
    }

    loadSpot(courseId: string): void {
        this.spotService.getSpots(courseId).subscribe((result) => {
            this.spots = result.spots;
        });
    }

    loadStampRallys(eventId: string, courseId: string) {
        this.stampRallyCustomerAnswerService
            .getStampRallyCustomerAnswer(eventId, courseId)
            .subscribe((result) => {
                this.stampRallyCustomerAnswersResponse = result;
                this.isCompleted =
                    result.stamp_rally_course_status === 'COMPLETED'
                        ? true
                        : false;
            });
    }

    onCheckFinish() {
        this.stampRallyNetGameLotteryCouponService
            .getLotteryCustomerPrize(this.eventId)
            .subscribe((result) => {
                this.isClaimed = result.claimed_lottery_customer_prize;
            });
    }

    checkSpotFinish(spotId: string): boolean {
        if (this.stampRallyCustomerAnswersResponse != undefined) {
            const check =
                this.stampRallyCustomerAnswersResponse.stamp_rally_customer_spots.find(
                    (x) => x.spot_id == spotId
                );
            if (check && check.spot_status === 'COMPLETED') {
                return true;
            }
        }
        return false;
    }

    backClick() {
        const url = `/stamp-rally-net-game-coupon/course/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}`;
        this.router.navigate([url]);
    }

    selectSpotMapClick(spotId: string, spotName: string) {
        sessionStorage.setItem('spotName', spotName);
        const url = `/stamp-rally-net-game-coupon/spot/map/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/spot/${spotId}/is-available/${this.isAvailable}`;
        this.router.navigate([url]);
    }

    questionClick(spotId: string, spotName: string, spotQRCodeId: string) {
        sessionStorage.setItem('spotName', spotName);
        if (this.checkSpotFinish(spotId)) {
            const url = `/stamp-rally-net-game-coupon/question/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/spot/${spotId}/is-available/${this.isAvailable}`;
            this.router.navigate([url]);
        } else {
            const url = `/stamp-rally-net-game-coupon/qr-code/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/spot/${spotId}/spot-qr-code/${spotQRCodeId}/is-available/${this.isAvailable}/route/question`;
            this.router.navigate([url]);
        }
    }

    onDrawSkill() {
        if (!this.isClaimed && this.isCompleted && this.isAvailable) {
            this.stampRallyNetGameLotteryCouponService
                .getLotteryCustomerPrize(this.eventId)
                .subscribe((result) => {
                    this.isClaimed = result.claimed_lottery_customer_prize;
                    if (!this.isClaimed && this.isCompleted && this.isAvailable) {
                        const url = `/stamp-rally-net-game-coupon/draw-skill/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/is-available/${this.isAvailable}`;
                        this.router.navigate([url]);
                    } else {
                        // TODO: Add translation
                        this.openDialog("このユーザーはすでに利用済みです。1ユーザー1回のみ利用できます。", 'Ok');
                    }
                });
        }
    }

    openDialog(message: string, action: string) {
        this.dialog.open(DialogModalComponent, {
            data: {
                message,
                action,
            },
        });
    }

}
