/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SpotService } from 'src/app/core/services/ge-spot/ge-spot.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { Spot } from 'src/app/core/models/spot/spot';

@Component({
    selector: 'app-maps-net-game-coupon',
    templateUrl: './maps-net-game-coupon.component.html',
    styleUrls: ['./maps-net-game-coupon.component.css'],
})
export class MapsNetGameCouponComponent implements OnInit {
    apiLoaded?: Observable<boolean>;
    options: google.maps.MapOptions = {
        center: { lat: 35.6894, lng: 139.692 },
        zoom: 16,
    };
    markerPosition: any = { lat: 35.6894, lng: 139.692 };
    title?: string = '';
    nameButtonAction? = 'Go course';
    isReady = false;
    courseId = '';
    eventId = '';
    shopDid = '';
    spotId = '';
    spots: Spot[] = [];
    spot: Spot | undefined;
    latitude = 0;
    longitude = 0;
    address?: string;
    isAvailable = '';
    translation?: Translation

    constructor(
        private spotService: SpotService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        httpClient: HttpClient
    ) {
        this.apiLoaded = httpClient
            .jsonp(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyA_aNDn4rNO40YtVE-hR28xVVf85Ilo8Us',
                'callback'
            )
            .pipe(
                map(() => true),
                catchError(() => of(true))
            );
    }

    ngOnInit(): void {
        this.loadTranslation();
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.spotId = this.activeRoute.snapshot.params['spotId'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
        this.loadSpot(this.courseId);
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.title = this.translation?.courseNameTitle;
        }
    }

    loadSpot(courseId: string): void {
        this.spotService.getSpots(courseId).subscribe((result) => {
            this.spots = result.spots;
            this.spot = this.spots.find((e) => e.spot_id == this.spotId);

            if (this.spot != undefined) {
                this.title = this.spot.name;
                this.latitude = this.spot.address_latitude;
                this.longitude = this.spot.address_longitude;
                this.address = this.spot?.address;
                this.isReady = true;
                this.options = {
                    center: { lat: this.latitude, lng: this.longitude },
                    zoom: 16,
                };
                this.markerPosition = {
                    lat: this.latitude,
                    lng: this.longitude,
                };
            }
        });
    }

    backClick() {
        const url = `/stamp-rally-net-game-coupon/spot/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/is-available/${this.isAvailable}`;
        this.router.navigate([url]);
    }
}
