import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { CouponCustomerPrizeRequest, CouponCustomerPrizeResponse } from '../../models/coupon-customer-prize/coupon-customer-prize';

const couponCustomerPrizePostUrl = environment.centerServer.domain + '/gotch-event/v1/coupon-customer-prize';

@Injectable({
    providedIn: 'root',
})
export class CouponCustomerPrizeService {
    constructor(private readonly http: HttpClient) { }

    postPrize(
        request: CouponCustomerPrizeRequest
    ): Observable<CouponCustomerPrizeResponse> {
        return this.http.post<CouponCustomerPrizeResponse>(
            couponCustomerPrizePostUrl,
            request,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
