/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { QrCode } from 'src/app/core/models/qr-code/qr-code';
import { Location } from '@angular/common';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalComponent } from '../../shared/dialog-modal/dialog-modal..component';
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';

@Component({
    selector: 'app-qr-code',
    templateUrl: './qr-code.component.html',
    styleUrls: ['./qr-code.component.css'],
})
export class QrCodeComponent implements OnInit {
    qrcodeView = true;
    scannerEnabled = true;
    scanQRCodeTitle? = 'Please, scan the QR Code';
    scannedData = '';
    lotteryId = '';
    qrCode: QrCode | undefined;
    eventType = '';
    eventId = '';
    shopDid = '';
    courseId = '';
    spotId = '';
    route = '';
    isAvailable = '';
    translation?: Translation;
    isAndroid = false

    public config: ScannerQRCodeConfig = {
        // fps: 1000,
        vibrate: 0,
        isBeep: false,
        // decode: 'macintosh',
        deviceActive: 1,
        constraints: { 
          audio: false,
          video: {
            width: window.innerWidth
          }
        } 
      };

    allowedFormats = [
        BarcodeFormat.QR_CODE,
        BarcodeFormat.EAN_13,
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX /*, ...*/,
    ];

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private location: Location,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.eventType = this.activeRoute.snapshot.params['eventType'];
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.spotId = this.activeRoute.snapshot.params['spotId'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
        this.route = this.activeRoute.snapshot.params['route'];
        const deviceType = sessionStorage.getItem('deviceType');
        this.isAndroid = deviceType === 'ANDROID';
        this.loadTranslation();
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.scanQRCodeTitle = this.translation?.scanQRCodeTitle;
        }
    }

    scanSuccessHandler($event: any) {
        this.qrCode = undefined;
        if (this.scannedData === $event) {
            return;
        }
        this.scannedData = $event;
        this.handleData();
    }

    private handleData() {
        if (this.eventType === 'lottery') {
            if (this.isJSON(this.scannedData)) {
                this.onLottety();
            } else {
                this.openDialogQrCode('E01:このQRコードは有効ではありません', 'Ok');
            }
        } else if (this.eventType === 'stamp-rally') {
            if (this.isJSON(this.scannedData)) {
                this.onStampRally();
            } else {
                this.onSpotQuestions();
            }
        } else if (this.eventType === 'coupon') {
            if (this.isJSON(this.scannedData)) {
                this.onCoupon();
            } else {
                this.openDialogQrCode('E02:このQRコードは有効ではありません', 'Ok');
            }
        }
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngAfterContentInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setTimeout(() => this.action!.start(), 1500);
    }

    @ViewChild('action') action: NgxScannerQrcodeComponent | undefined;

    public onEvent(e: ScannerQRCodeResult[]): void {
        console.log(e);
        if (e.length === 0 || this.scannedData === e[0].value) {
            return;
        }
        this.scannedData = e[0].value;
        this.handleData();
    }

    public handle(action: any, fn: string): void {
        action[fn]().subscribe(console.log, alert);
    }

    onLottety() {
        this.qrCode = JSON.parse(this.scannedData);
        if (this.qrCode) {
            if (this.qrCode.shopDid === this.shopDid) {
                this.onLottetyRouterNavigate()
            } else {
                this.openDialogQrCode('E03:このQRコードは有効ではありません', 'Ok');
            }
        } else {
            this.openDialogQrCode('E04:このQRコードは有効ではありません', 'Ok');
        }
    }

    onStampRally() {
        this.qrCode = JSON.parse(this.scannedData);
        if (this.qrCode) {
            if (this.qrCode.shopDid === this.shopDid) {
                this.onStampRallyRouterNavigate()
            } else {
                this.openDialogQrCode('E05:このQRコードは有効ではありません', 'Ok');
            }
        } else {
            this.openDialogQrCode('E06:このQRコードは有効ではありません', 'Ok');
        }
    }

    onCoupon() {
        sessionStorage.setItem('claimer_shop_did', '');
        this.qrCode = JSON.parse(this.scannedData);
        if (this.qrCode) {
            if (this.qrCode.shopDid === this.shopDid) {
                sessionStorage.setItem('claimer_shop_did', this.qrCode.shopDid);
                this.onCouponRouterNavigate()
            } else {
                this.openDialogQrCode('E11:このQRコードは有効ではありません', 'Ok');
            }
        } else {
            this.openDialogQrCode('E12:このQRコードは有効ではありません', 'Ok');
        }
    }

    onSpotQuestions() {
        if (this.spotId === this.scannedData) {
            this.onSpotQuestionRouterNavigate();
        } else {
            this.openDialogQrCode('E13:このQRコードは有効ではありません', 'Ok');
        }
    }

    onLottetyRouterNavigate() {
        if (this.route == 'draw') {
            const url = `/lottery/draw-lottery/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}`;
            this.router.navigate([url]);
        } else {
            this.openDialogQrCode('E14:このQRコードは有効ではありません', 'Ok');
        }
    }

    onStampRallyRouterNavigate() {
        if (this.route == 'course') {
            const url = `/stamp-rally/course/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}`;
            this.router.navigate([url]);
        } else if (this.route == 'prize') {
            const url = `/stamp-rally/prize/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/is-available/${this.isAvailable}`;
            this.router.navigate([url]);
        } else {
            this.openDialogQrCode('E15:このQRコードは有効ではありません', 'Ok');
        }
    }

    onCouponRouterNavigate() {
        if (this.route == 'coupon') {
            const url = `/coupon/prize/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}`;
            this.router.navigate([url]);
        } else {
            this.openDialogQrCode('E18:このQRコードは有効ではありません', 'Ok');
        }
    }

    onSpotQuestionRouterNavigate() {
        const url = `/stamp-rally/question/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/spot/${this.spotId}/is-available/${this.isAvailable}`;
        this.router.navigate([url]);
    }

    isJSON(text: string) {
        try {
            return (JSON.parse(text) && !!text);
        } catch {
            return false;
        }
    }

    openDialogQrCode(message: string, action: string) {
        this.dialog.open(DialogModalComponent, {
            data: {
                message,
                action,
            },
        });
    }

    backClicked() {
        this.location.back();
    }
}
