import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/core/models/course/course';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { CourseService } from 'src/app/core/services/ge-course/ge-course.service';
import { EventCourseCustomerService } from 'src/app/core/services/ge-event-course-customer/ge-event-course-customer.service';
import { EventCourseCustomerPost, EventCourseCustomerPatch } from 'src/app/core/models/event-course-customer/event-course-customer';

@Component({
    selector: 'app-course',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.css'],
})
export class CourseComponent implements OnInit {
    title?= 'Select a course';
    nameButtonAction?= 'Get started';
    courses: Course[] = [];
    course?: Course;
    courseSelectDescription = '';
    eventId = '';
    shopDid = '';
    isReady = false;
    isCourseReady = false;
    isCourseSelect = false;
    isAvailable = '';
    translation?: Translation
    startedEventCourseCustomer?: string | null;
    startedCourseId?: string | null;

    constructor(
        private courseService: CourseService,
        private eventCourseCustomerService: EventCourseCustomerService,
        private activeRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
        this.startedEventCourseCustomer = sessionStorage.getItem('startedEventCourseCustomer');
        this.startedCourseId = sessionStorage.getItem('startedCourseId');
        this.loadTranslation();
        this.loadCourses(this.eventId);
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.title = this.translation?.selectCourseTitle;
            this.nameButtonAction = this.translation?.getStartedButton;
        }
    }

    loadCourses(eventId: string): void {
        this.courseService.getCourses(eventId).subscribe((result) => {
            this.courses = result.courses;
            this.course = this.courses.find(({ course_id }) => course_id === this.startedCourseId);
            if (this.course) this.selectCourse(this.course);
            this.isReady = true;
        });
    }

    checkedCourse(course: Course): boolean {
        return course.course_id === this.startedCourseId;
    }

    selectCourse(course: Course): void {
        this.course = course;
        this.isCourseReady = true;
        this.isCourseSelect = true;
        sessionStorage.setItem('courseName', course.name)
    }

    backClick() {
        const url = `/stamp-rally/event/${this.eventId}/shop-did/${this.shopDid}/is-available/${this.isAvailable}`;
        this.router.navigate([url]);
    }

    selectCourseClick() {
        if (this.startedEventCourseCustomer === 'true') {
            this.onPatch();
        } else {
            this.onPost();
        }
    }

    onPost() {
        if (this.course) {
            const eventCourseCustomerPost: EventCourseCustomerPost = {
                event_id: this.eventId,
                course_id: this.course.course_id
            };
            this.eventCourseCustomerService.postCourse(eventCourseCustomerPost).subscribe(() => {
                this.goSpot();
            });
        }
    }

    onPatch() {
        if (this.course) {
            const EventCourseCustomerPatch: EventCourseCustomerPatch = {
                course_id: this.course.course_id
            };
            this.eventCourseCustomerService.patchCourse(EventCourseCustomerPatch, this.eventId).subscribe(() => {
                this.goSpot();
            });
        }
    }

    goSpot() {
        if (this.course) {
            sessionStorage.setItem('startedCourseId', this.course.course_id);
            const url = `/stamp-rally/spot/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.course.course_id}/is-available/${this.isAvailable}`;
            this.router.navigate([url]);
        }
    }
}
