import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { StampRallyCustomerPrizeResponse } from '../../models/stamp-rally-customer-prize/stamp-rally-customer-prize';

const eventIdToken = '{event_id}';
const stampRallyPrizeGetUrl = environment.centerServer.domain + '/gotch-event/v1/stamp-rally-customer-prize/event/{event_id}';

@Injectable({
    providedIn: 'root',
})
export class StampRallyCustomerPrizeService {
    constructor(private readonly http: HttpClient) { }

    getStampRallyCustomerPrize(
        eventId: string
    ): Observable<StampRallyCustomerPrizeResponse> {
        const getURL = stampRallyPrizeGetUrl
            .replace(eventIdToken, eventId)
        return this.http.get<StampRallyCustomerPrizeResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
