import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { EventCourseCustomerPost, EventCourseCustomerPatch, EventCourseCustomerResponse } from 'src/app/core/models/event-course-customer/event-course-customer';

const eventCourseCustomerApiUrl = environment.centerServer.domain + '/gotch-event/v1/event-course-customer/event/{eventId}';
const eventCourseCustomerPostApiUrl = environment.centerServer.domain + '/gotch-event/v1/event-course-customer';
const eventIdToken = '{eventId}';

@Injectable({
    providedIn: 'root',
})
export class EventCourseCustomerService {
    constructor(
        private readonly http: HttpClient
    ) {}
    
    getEventCoursesCustomers(eventId: string): Observable<EventCourseCustomerResponse> {
        const getURL = eventCourseCustomerApiUrl
            .replace(eventIdToken, eventId);

        return this.http.get<EventCourseCustomerResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }

    postCourse(request: EventCourseCustomerPost): Observable<EventCourseCustomerResponse> {
        return this.http.post<EventCourseCustomerResponse>(
            eventCourseCustomerPostApiUrl,
            request,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }

    patchCourse(request: EventCourseCustomerPatch, eventId: string): Observable<EventCourseCustomerResponse> {
        return this.http.patch<EventCourseCustomerResponse>(
            eventCourseCustomerApiUrl.replace(eventIdToken, eventId),
            request,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}