import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import { StampRallyCustomerPrizeShopVoucherResponse, StampRallyCustomerPrizeRequest } from '../../models/stamp-rally-customer-prize-shop-voucher/stamp-rally-customer-prize-shop-voucher';

const stampRallyShopVoucherPrizePostUrl = environment.centerServer.domain + '/gotch-event/v1/stamp-rally-shop-voucher-customer-prize';

@Injectable({
    providedIn: 'root',
})
export class StampRallyCustomerPrizeShopVoucherService {
    constructor(private readonly http: HttpClient) { }

    postShopVoucherPrize(
        request: StampRallyCustomerPrizeRequest
    ): Observable<StampRallyCustomerPrizeShopVoucherResponse> {
        return this.http.post<StampRallyCustomerPrizeShopVoucherResponse>(
            stampRallyShopVoucherPrizePostUrl,
            request,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }
}
