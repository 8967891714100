<div *ngIf="isReady" class="page">
    <div class="header">
        <div (click)="backClick()" class="button-back">
            <button mat-button>
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
        </div>
        <div class="title-page">
            <span class="title-text">{{ title }}</span>
        </div>
    </div>
    <div class="content">
        <div class="select-course">
            <mat-grid-list cols="1" rowHeight="2:1">
                <mat-grid-tile
                    ><img src="../../../../assets/select-course.svg"
                /></mat-grid-tile>
            </mat-grid-list>
        </div>
        <div class="content-center">
            <div>
                <mat-radio-group
                    aria-labelledby="course-radio-group-label"
                    class="course-radio-group"
                >
                    <mat-radio-button
                        class="course-radio-button"
                        *ngFor="let item of courses"
                        [value]="item.course_id"
                        (click)="selectCourse(item)"
                        [checked]="checkedCourse(item)"
                    >
                        {{ item.name }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="isCourseReady" class="description">
                <p [innerHTML]="course?.description"></p>
            </div>
        </div>
    </div>
    <div class="footer">
        <button
            [class]="isCourseSelect ? 'gotch-button' : 'gotch-button-disabled'"
            [disabled]="!isCourseSelect"
            (click)="selectCourseClick()"
        >
            {{ nameButtonAction }}
        </button>
    </div>
</div>
