/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { AnimationOptions } from 'ngx-lottie';
import { StampRallyNetGameSkillService } from 'src/app/core/services/stamp-rally-net-game-skill/stamp-rally-net-game-skill.service';
import { StampRallyNetGameLotteryCouponService } from 'src/app/core/services/stamp-rally-net-game-lottery-coupon/stamp-rally-net-game-lottery-coupon.service';
import { StampRallyNetGameSkill } from 'src/app/core/models/stamp-rally-net-game-skill/stamp-rally-net-game-skill';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalComponent } from 'src/app/shared/dialog-modal/dialog-modal..component';

@Component({
    selector: 'app-draw-skill-net-game-coupon',
    templateUrl: './draw-skill-net-game-coupon.component.html',
    styleUrls: ['./draw-skill-net-game-coupon.component.css'],
})
export class DrawSkillNetGameCouponComponent implements OnInit {
    loaded = false;
    submitted = false;
    nameButtonAction? = '';
    eventId = '';
    shopDid = '';
    courseId = '';
    isAvailable = '';
    translation: Translation | undefined;
    skill: StampRallyNetGameSkill | undefined;

    options: AnimationOptions = {
        path: '/assets/loading.json',
    };

    animationCreated(): void {
        setTimeout(() => {
            this.loaded = true;
            this.getRandomSkill();
        }, 2500);
    }

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private stampRallyNetGameSkillService: StampRallyNetGameSkillService,
        private stampRallyNetGameLotteryCouponService: StampRallyNetGameLotteryCouponService,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.eventId = this.activeRoute.snapshot.params['eventId'];
        this.shopDid = this.activeRoute.snapshot.params['shopDid'];
        this.courseId = this.activeRoute.snapshot.params['courseId'];
        this.isAvailable = this.activeRoute.snapshot.params['isAvailable'];
        this.loadTranslation();
    }

    loadTranslation() {
        const translation = sessionStorage.getItem('translation');
        if (translation != null) {
            this.translation = JSON.parse(translation);
            this.nameButtonAction = this.translation?.drawLotteryNetGameButton;
        }
    }

    private getRandomSkill() {
        this.stampRallyNetGameSkillService
            .getRandomSkill()
            .subscribe((result) => {
                this.skill = result.skill;
            });
    }

    onDrawLottery() {
        this.stampRallyNetGameLotteryCouponService
            .getLotteryCustomerPrize(this.eventId)
            .subscribe((result) => {
                const isClaimed = result.claimed_lottery_customer_prize;
                if (!isClaimed && !this.submitted) {
                    this.submitted = true;
                    const url = `/stamp-rally-net-game-coupon/draw-lottery/event/${this.eventId}/shop-did/${this.shopDid}/course/${this.courseId}/skill-type/${this.skill?.type}/is-available/${this.isAvailable}`;
                    this.router.navigate([url]);
                } else {
                    this.openDialog("このユーザーはすでに利用済みです。1ユーザー1回のみ利用できます。", 'Ok');
                }
            });
    }

    openDialog(message: string, action: string) {
        this.dialog.open(DialogModalComponent, {
            data: {
                message,
                action,
            },
        });
    }
}
