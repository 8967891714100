/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, Optional,  } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    message: '',
    action: ''
}

@Component({
    selector: 'app-dialog-modal',
    templateUrl: './dialog-modal.component.html',
    styleUrls: ['./dialog-modal.component.css'],
})

export class DialogModalComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogModalComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData
      ) {}
}
