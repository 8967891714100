/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';
declare function sendData(data: string): string;

@Component({
    selector: 'app-draw-lottery-result',
    templateUrl: './draw-lottery-result.component.html',
    styleUrls: ['./draw-lottery-result.component.css'],
})
export class DrawLotteryResultComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
        const lotteryPrize = sessionStorage.getItem('lottery_prize');
        if (lotteryPrize) {
            sendData(lotteryPrize);
        }
    }
}
