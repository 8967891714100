<div class="page">
    <div class="header">
        <div (click)="backClick()" class="button-back">
            <button mat-button>
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
        </div>
        <div class="title-page">
            <span class="title-text">{{ title }}</span>
        </div>
    </div>
</div>
<div *ngIf="isReady">
    <div class="map" *ngIf="apiLoaded | async">
        <google-map width="100%" height="100%" [options]="options">
            <map-marker [position]="markerPosition"></map-marker>
        </google-map>
    </div>
</div>
