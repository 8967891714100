import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Functions } from '../../helpers/functions';
import {
    StampRallyNetGameLotteryCouponRequest,
    StampRallyNetGameLotteryCouponResponse,
    StampRallyNetGameLotteryCustomerPrizeCouponResponse,
} from '../../models/stamp-rally-net-game-lottery-coupon/stamp-rally-net-game-lottery-coupon';

const stampRallyNetGameLotteryCouponPostUrl =
    environment.centerServer.domain +
    '/gotch-event/v1/stamp-rally-net-game/draw-lottery';
const stampRallyNetGameLotteryCouponGetUrl =
    environment.centerServer.domain +
    '/gotch-event/v1/stamp-rally-net-game/lottery/event/{eventId}';
const eventIdToken = '{eventId}';

@Injectable({
    providedIn: 'root',
})
export class StampRallyNetGameLotteryCouponService {
    constructor(private readonly http: HttpClient) {}

    postDrawLottery(
        request: StampRallyNetGameLotteryCouponRequest
    ): Observable<StampRallyNetGameLotteryCouponResponse> {
        return this.http
            .post<StampRallyNetGameLotteryCouponResponse>(
                stampRallyNetGameLotteryCouponPostUrl,
                request,
                Functions.getDefaultHeaderWithBearerToken()
            )
            .pipe(catchError(this.handleError));
    }

    getLotteryCustomerPrize(
        eventId: string
    ): Observable<StampRallyNetGameLotteryCustomerPrizeCouponResponse> {
        const getURL = stampRallyNetGameLotteryCouponGetUrl.replace(
            eventIdToken,
            eventId
        );
        return this.http.get<StampRallyNetGameLotteryCustomerPrizeCouponResponse>(
            getURL,
            Functions.getDefaultHeaderWithBearerToken()
        );
    }

    private handleError(error: any) {
        return throwError(error);
    }
}
