/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Translation } from 'src/app/core/models/internationalization/internationalization';
import { EventService } from 'src/app/core/services/ge-event/ge-event.service';
import { GeEvent } from 'src/app/core/models/ge-event/ge-event';
import * as moment from 'moment-timezone';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare function valueGotFromAPP(message: any): any;
declare function getData(data: string): string;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {

    options: AnimationOptions = {
        path: '/assets/loading.json',
    };

    animationCreated(animationItem: AnimationItem): void {
        console.log(animationItem);
    }

    translation: Translation | undefined
    geEvent?: GeEvent;

    constructor(
        private router: Router,
        private eventService: EventService,
    ) { }

    ngOnInit(): void {
        console.log('Start WebView');
        this.getDataFromApp();
        
        // const mock = {
        //     language: 'ja',
        //     access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiMDFIMldDSFdQTjY3NTNSUjY3NlZYNVk3U04iLCJpYXQiOjE3MjU4NDEyMzAsImV4cCI6MTcyNTkyNzYzMH0.OPn9H-0ZL2pR-OB8yI-1x2RNGbjNnduj6xoXwVWpC_c",
        //     refresh_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiMDFIMldDSFdQTjY3NTNSUjY3NlZYNVk3U04iLCJpYXQiOjE3MjU4NDEyMzAsImV4cCI6NDg3OTQ0MTIzMH0.r0HCIgX_j6ErZkTRCGm6ZErxcz15ufk3ekKz4p9z72o",
        //     eventId: '01J7A276YAX390P7NZFG34AEEY',
        //     deviceType: 'ANDROID',
        // };
        // valueGotFromAPP(JSON.stringify(mock));
    }

    getDataFromApp() {
        const getDataIOS = {
            getDataIOS: true
        }
        console.log('getDataIOS');
        getData(JSON.stringify(getDataIOS));
    }

    @HostListener('window:events.load', ['$event'])
    loadEvents(event: any): void {
        this.loadLanguage(event);
    }

    loadLanguage(event: any) {
        if (event.detail.language === 'en') {
            const translation = {
                eventTitle: "Event",
                stampRallyEvent: "Stamp Rally",
                lotteryEvent: "Lottery",
                scanQRCodeButton: "Scan QR Code to start",
                selectCourseTitle: "Select a course",
                getStartedButton: "Get started",
                selectNewCourseButton: "Start new course",
                continuePreviousCourseButton: "Continue previous course",
                courseNameTitle: "Course name",
                getCouponButton: "Get coupon",
                getNetGameButton: "Start Game",
                getNetGameCoupon: "Get a Coupon",
                scanQRCodeTitle: "Please, scan the QR Code",
                spotNameTitle: "Spot name",
                sendAndCompleteButton: "Send and complete",
                completeButton: "Complete",
                drawLotteryButton: "Draw Lottery",
                goodLuckTitle: "Good luck!",
                youWinPrizeTitle: "You win a prize!!",
                scanQRCodeToClaimYourPrizeButton: "Scan QR Code to claim your prize",
                notThisTimeTitle: "Not this time...",
                finishButton: "Finish",
                maybeNextTimeYouWillWin: "Maybe next time you will win!",
                scheduleText: "Schedule",
                correct: "Correct",
                wrong: "Wrong",
                thankYouVoucherTitle: "Thank you!",
                thankYouVoucherButton: "Finish",
                drawLotteryNetGameButton: "Play",
                errorOnDrawLottery: "Already claimed"
            }
            this.translation = translation;
        } else {
            const translation = {
                eventTitle: "イベント",
                stampRallyEvent: "スタンプラリー",
                lotteryEvent: "抽選くじ",
                scanQRCodeButton: "QRコードを読み取って参加する",
                selectCourseTitle: "コースを選択する",
                getStartedButton: "このコースではじめる",
                selectNewCourseButton: "別のコースではじめる",
                continuePreviousCourseButton: "続ける",
                courseNameTitle: "コース名",
                getCouponButton: "商品券をもらう",
                getNetGameButton: "ゲームをはじめる",
                getNetGameCoupon: "引換券をもらう",
                scanQRCodeTitle: "カメラをQRコードに向けてください",
                spotNameTitle: "クイズポイント名",
                sendAndCompleteButton: "回答をおくる",
                completeButton: "完了する",
                drawLotteryButton: "くじを引く",
                goodLuckTitle: "Good luck!",
                youWinPrizeTitle: "当たり！",
                scanQRCodeToClaimYourPrizeButton: "QRコードを読み取って商品券をもらう",
                notThisTimeTitle: "残念・・・",
                finishButton: "画面を閉じる",
                maybeNextTimeYouWillWin: "はずれ",
                scheduleText: "スケジュール",
                correct: "正解",
                wrong: "不正解",
                thankYouVoucherTitle: "ありがとうございます！",
                thankYouVoucherButton: "完了",
                drawLotteryNetGameButton: "プレイ",
                errorOnDrawLottery: "このユーザーはすでに利用済みです。1ユーザー1回のみ利用できます。"
            }
            this.translation = translation;
        }

        this.loadSessionStorage(event);
    }

    loadSessionStorage(event: any) {
        sessionStorage.clear();
        sessionStorage.setItem(
            'translation',
            JSON.stringify(this.translation)
        );
        sessionStorage.setItem(
            'access_token',
            'Bearer ' + event.detail.access_token
        );
        sessionStorage.setItem(
            'refresh_token',
            'Bearer ' + event.detail.refresh_token
        );
        sessionStorage.setItem(
            'language',
            event.detail.language
        );
        sessionStorage.setItem(
            'deviceType',
            event.detail.deviceType
        );

        setTimeout(() => {
            this.loadDetails(event);
        }, 300);
    }

    loadDetails(event: any) {
        if (event.detail.eventId) {
            this.eventService.getEvent(event.detail.eventId).subscribe((result) => {
                this.geEvent = result.event;
                this.loadNavigation(this.geEvent);
            });
        }
    }

    loadNavigation(geEvent: any) {
        const currentDate = moment.tz('Asia/Tokyo').format('YYYY-MM-DD');
        const endDate = moment.tz(geEvent.end_date.replace(/\//g, "-"), 'Asia/Tokyo').format('YYYY-MM-DD');
        let isAvailable = false;
        if (currentDate <= endDate) {
            isAvailable = true;
        }
        if (geEvent.type == 'LOTTERY' && geEvent.ticket_type == 'COUPON') {
            const url = `lottery/event/${geEvent.event_id}/shop-did/${geEvent.shop_did}/is-available/${isAvailable}`;
            this.router.navigate([url]);
        } else if (geEvent.type == 'COUPON' && geEvent.ticket_type == 'COUPON') {
            const url = `coupon/event/${geEvent.event_id}/shop-did/${geEvent.shop_did}/is-available/${isAvailable}`;
            this.router.navigate([url]);
        } else if (geEvent.type == 'STAMP-RALLY' && geEvent.ticket_type == 'COUPON') {
            const url = `stamp-rally/event/${geEvent.event_id}/shop-did/${geEvent.shop_did}/is-available/${isAvailable}`;
            this.router.navigate([url]);
        } else if ((geEvent.type == 'FREE_VOUCHER' || geEvent.type == 'FREE-VOUCHER') && geEvent.ticket_type == 'SHOP-VOUCHER') {
            const url = `free-voucher/event/${geEvent.event_id}/shop-did/${geEvent.shop_did}/is-available/${isAvailable}`;
            this.router.navigate([url]);
        } else if (geEvent.type == 'STAMP-RALLY' && geEvent.ticket_type == 'PAPER-VOUCHER') {
            const url = `stamp-rally-paper-voucher/event/${geEvent.event_id}/shop-did/${geEvent.shop_did}/is-available/${isAvailable}`;
            this.router.navigate([url]);
        } else if (geEvent.type == 'STAMP-RALLY' && geEvent.ticket_type == 'SHOP-VOUCHER') {
            const url = `stamp-rally-shop-voucher/event/${geEvent.event_id}/shop-did/${geEvent.shop_did}/is-available/${isAvailable}`;
            this.router.navigate([url]);
        } else if (geEvent.type == 'STAMP-RALLY-NET-GAME' && geEvent.ticket_type == 'COUPON') {
            const url = `stamp-rally-net-game-coupon/event/${geEvent.event_id}/shop-did/${geEvent.shop_did}/is-available/${isAvailable}`;
            this.router.navigate([url]);
        }        
    }
}
